import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { InstrumentComponentBase } from './instrument-component-base';
import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { decodeKeys, UNCRecord, UNCType } from 'src/app/model/uncsettings';
import { ConnectionService } from 'src/app/services/connection.service';
import { rootViews } from './log-config';
import { InstrumentInfo } from 'src/app/model/unc-types';


const log = rootViews.getChildCategory("instrument");

export interface UNCKeyInfo {
  type: UNCType;

  keys: string[];

  sensors: string;
}

@Component({
  template: ''
})
export abstract class InstrumentViewComponentBaseComponent extends InstrumentComponentBase implements OnInit, OnDestroy {

  private _supportKeySelection: boolean = false;
  private _trackKeyPresses: boolean = false;
  private _trackUNCPresses: boolean = false;
  private _printing: boolean = false;
  private _useStateColors: boolean = true

  public abstract get instrument() : InstrumentInfo;

  constructor(
    protected cs: ConnectionService,
    protected cd: ChangeDetectorRef) {

    super(cs);
  }

  protected _pressure : number = 0;

  public get pressure() : number {
    return this._pressure;
  }

  public set pressure(value : number) {
    this._pressure = value;
    this.cd.detectChanges();
  }

  @Input()
  set printing(value: boolean) {
    this._printing = value;
  }

  public get printing() : boolean {
    return this._printing;
  }

  @Input()
  set useStateColors(flag: boolean) {
    this._useStateColors = flag;
  }

  get useStateColors(): boolean {
    return this._useStateColors;
  }


  @Input()
  set supportKeySelection(flag: boolean) {

    if (this._supportKeySelection != flag) {
      this._supportKeySelection = flag;
      if (this.currentInstrument) this.setupKeySelection(flag, this.currentInstrument);
    }
  }

  get supportKeySelection(): boolean {
    return this._supportKeySelection;
  }

  @Input()
  set trackKeyPresses(flag: boolean) {
    this.setTrackKeyPresses(flag).then();
  }

  public async setTrackKeyPresses(flag: boolean) {
    this.updateKeyPressesTracking(flag).then();
  }

  get trackKeyPresses(): boolean {
    return this._trackKeyPresses;
  }

  async updateKeyPressesTracking(flag: boolean) {

    if (this._trackKeyPresses != flag) {
      this._trackKeyPresses = flag;
      if (this.currentInstrument) await this.setupKeyPresses(flag, this.currentInstrument);
    }
  }

  
  @Input()
  set trackUNCPresses(flag: boolean) {
    this.updateUNCPressesTracking(flag).then();
  }

  async updateUNCPressesTracking(flag: boolean) {

    if (this._trackUNCPresses != flag) {
      this._trackUNCPresses = flag;
      if (this.currentInstrument) await this.setupUNCPresses(flag, this.currentInstrument);
    }
  }

  get trackUNCPresses(): boolean {
    return this._trackUNCPresses;
  }

  
  @ViewChild('Keys')
  keys!: ElementRef;
  
  @Output()
  onKeysPressed = new EventEmitter<UNCKeyInfo>();

  private _uncType: UNCType = UNCType.FACTORY; 

  get uncType(): UNCType {
    return this._uncType;
  }

  set uncType(value: UNCType) {
    this._uncType = value;
  }

  private _pressed: string[] = [];

  set pressed(value: string[]) {
    this._pressed = value;
    this.cd.detectChanges()
  }

  get pressed(): string[] {
    return this._pressed;
  }


  private async setupKeySelection(flag: boolean, instrument: InstrumentConnection) {

  }

  private _isKeypressActive: boolean = false;

  private async setupKeyPresses(flag: boolean, instrument: InstrumentConnection) {
    
    if (flag === undefined) return;

    if (!flag) {
      instrument.stopKeystrokeMessages();
  
      if (this._keypressListener) {
        instrument.off('keypress', this._keypressListener);
        this._keypressListener = undefined;
      }
    }
    else {
      let comp = this;
      
      this._keypressListener = (data: Uint8Array, receivedTime: number | undefined) => {
        
        if (data.length >= 12) {
          let keyState = instrument.parseKeyRecord(data)
          
          if (keyState) {
            log.debug("KeyPress: " + keyState);
            let keys = decodeKeys(keyState, this.instrument);
            this._pressed = keys;
            this._uncType = UNCType.FACTORY;
            this.onKeysPressed.emit({
              type: UNCType.FACTORY,
              keys: keys, 
              sensors: keyState
            });
            this.cd.detectChanges();
          }
        }
      }
      
      instrument.on("keypress", this._keypressListener);
      
      instrument.startKeystrokeMessages();
    }

    this._isKeypressActive = flag;
  }
  
  private _isUNCActive: boolean = false;
  
  private async setupUNCPresses(flag: boolean, instrument: InstrumentConnection) {
      
    if (!flag) {
      log.debug("Tuning off UNC listening for diagram.");

      if (instrument.listenerCount('midi') == 0) {
        await instrument.stopCCMessages();
      }
      
      if (instrument.listenerCount('unc') == 0) {
        await instrument.stopUNCMessages();
      }

      if (this._uncListener) {
        instrument.off('unc', this._uncListener);
        this._uncListener = undefined;
      }

      if (this._midiListener) {
        instrument.off('midi', this._midiListener);
        this._midiListener = undefined;
      }
    }
    else {
      log.debug("Tuning on UNC listening for diagram.");

      let comp = this;

      if (!this._uncListener) {

        this._uncListener = (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => {
  
          if (!this._trackUNCPresses) return;
  
          if (data.keyState) {
            let keys = decodeKeys(data.keyState, this.instrument);
            this._pressed = keys;

            if (this._useStateColors) {
              this._uncType = data.type;
            }
            else {
              this.uncType = UNCType.FACTORY
            }

            this.onKeysPressed.emit({
              type: data.type,
              keys: keys, 
              sensors: data.keyState
            });
  
            this.cd.detectChanges();
          }
        }

        instrument.on("unc", this._uncListener);
      }

      if (!this._midiListener) {

        this._midiListener = (data: Uint8Array, receivedTime: number | undefined) => {
          if (((data[0] & 0xf0) == 0xb0 ) && (data[1] == 0x2)) {
            this._pressure = data[2] / 127 * 10;
          }
        };
  
        instrument.on('midi', this._midiListener);
      }

      if (instrument.listenerCount('unc') == 1) {
        await instrument.startUNCMessages();
      }

      if (instrument.listenerCount('midi') == 1) {
        await instrument.startCCMessages();
      }
    }    

    this._isUNCActive = flag;
  }

  showKeys(keyState: string) {
    let keys = decodeKeys(keyState, this.instrument);
    this._pressed = keys;
    this.onKeysPressed.emit({
      type: UNCType.FACTORY,
      keys: keys, 
      sensors: keyState
    });    
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    if (this.currentInstrument) {
      this.enableListeners(this.currentInstrument);
    }
  }

  async ngOnDestroy(): Promise<void> {

    await super.ngOnDestroy();

    if (this.currentInstrument) {
      await this.disableListeners(this.currentInstrument);
    }
  }

  async enableListeners(instrument: InstrumentConnection) {
    await this.setupKeyPresses(this._trackKeyPresses, instrument);
    await this.setupKeySelection(this._supportKeySelection, instrument);
    await this.setupUNCPresses(this._trackUNCPresses, instrument);    
  }

  async disableListeners(instrument: InstrumentConnection) {
    await this.setupKeyPresses(false, instrument);
    await this.setupKeySelection(false, instrument);
    await this.setupUNCPresses(false, instrument);
  }

  private _keypressListener?: (data: Uint8Array, receivedTime: number | undefined) => void;

  private _uncListener?: (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => void;

  private _midiListener?: (data: Uint8Array, receivedTime: number | undefined) => void;

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {
  
    if (before) {
      await this.disableListeners(before);
    } 

    if (current) {
      await this.enableListeners(current);
    }
  }

  @Output() 
  onKeyClicked = new EventEmitter<string>();

  get selected(): string[] {
    return this._selected;
  }

  set selected(keys: string[]) {
    this._selected = keys;
    this.cd.detectChanges()
  }

  private _selected: string[] = [];

  clickedKey(event: any) {

    if (!this.supportKeySelection) {
      return;
    }
   
    var clickedKey: string = ""

    for(var key in this.instrument.keyMap) {

      let map = this.instrument.keyMap[key];

      if (map) {
        if (map.name === event.target.id) {
          clickedKey = map.name
          break;
        }
      }
    }
     
    this.onKeyClicked.emit(clickedKey);
  }  
}
