import {UNCType} from "./uncsettings";

export type RecordingTime = DOMHighResTimeStamp;

export interface RecordingEvent<T extends RecordingEvent<any>> {

    get startTime() : RecordingTime;

    get endTime(): RecordingTime;

    get duration(): number;

    set duration(value: number);

    set timeOffset(time : RecordingTime)

    get timeOffset() : RecordingTime;

    get relativeStart() : RecordingTime;

    get relativeEnd() : RecordingTime;

    readonly measure: number;

    assignMeasure(measure: number) : number;

    // Perform post-recording operations on the recorded events
    process(index: number, events: RecordingEvent<any>[]) : void;

    clone(time : RecordingTime) : T;
}

export abstract class EventBase<T extends RecordingEvent<T>> implements RecordingEvent<T> {

    protected _measure: number = -1;

    get measure(): number {
        return this._measure;
    }

    protected constructor(time: RecordingTime = performance.now()) {
        this._timeOffset = time;
        this._startTime = time;
    }

    protected _timeOffset: RecordingTime;

    set timeOffset(time : RecordingTime) {
        this._timeOffset = time;
    }

    get timeOffset() : RecordingTime {
        return this._timeOffset;
    }

    get relativeStart() : RecordingTime {
        return this.startTime - this.timeOffset;
    }

    get relativeEnd() : RecordingTime {
        return this.relativeStart + this.duration;
    }

    protected _startTime: number;

    get startTime() : number {
        return this._startTime;
    }

    set startTime(value : number) {
        this._startTime = value;
    }

    protected _duration: number = 0;

    get duration() : number {
        return this._duration;
    }

    set duration(value: number) {
        this._duration = value;
    }

    get endTime(): number {
        return this._startTime + this._duration;
    }

    assignMeasure(measure: number): number {
        this._measure = measure;
        return this.measure;
    }

    abstract process(index: number, events: RecordingEvent<any>[]): void;

    abstract clone(time : RecordingTime) : T;
}

export abstract class NoteBaseEvent<T extends RecordingEvent<T>> extends EventBase<T> {

    get isTone() : boolean {
        return this.note != -1;
    }

    protected constructor(readonly note: number = -1, time: RecordingTime = performance.now()) {
        super(time);
    }
}

export class NoteEvent extends NoteBaseEvent<NoteEvent> {

    readonly isNoteOn: boolean;

    private _startNoteIndex: number = -1;

    public get startNoteIndex(): number {
        return this._startNoteIndex;
    }

    public set startNoteIndex(value: number) {
        this._startNoteIndex = value;
    }

    constructor(note: number = -1, onEvent : boolean, time: RecordingTime = performance.now()) {
        super(note, time);

        this.isNoteOn = onEvent;
    }

    process(index: number, events: RecordingEvent<any>[]): void {

        if (this.isNoteOn) {
            let noteOffIndex = events.findIndex( (value, valueIndex) => {
                return (valueIndex > index)
                    && (value instanceof NoteEvent)
                    && (!value.isNoteOn);
            });

            if (noteOffIndex != -1) {
                let eventNoteOff = events[noteOffIndex] as NoteEvent;

                this._duration = eventNoteOff._startTime - this._startTime;

                eventNoteOff.startNoteIndex = index;
                eventNoteOff._duration = this._duration;
                eventNoteOff._startTime = this._startTime;
            }
        }
    }

    clone(time: RecordingTime): NoteEvent {
        return new NoteEvent(this.note, this.isNoteOn, time);
    }
}

export class ListenedNoteEvent extends NoteBaseEvent<ListenedNoteEvent> {

    constructor(note: number, time: RecordingTime = performance.now(), _duration: number = -1) {
        super(note, time);
    }

    process(index: number, events: RecordingEvent<any>[]): void {
    }

    clone(time: RecordingTime): ListenedNoteEvent {
        return new ListenedNoteEvent(this.note, time, this._duration);
    }
}

export class MetronomeEvent extends EventBase<MetronomeEvent> {

    assignMeasure(measure: number): number {
        return this.measure;
    }

    constructor(_measure: number, readonly tick: number, time: RecordingTime = performance.now()) {
        super(time);
    }

    get endTime(): number {
        return this._startTime;
    }

    process(index: number, events: RecordingEvent<any>[]): void {
    }

    clone(time: RecordingTime): MetronomeEvent {
        return new MetronomeEvent(this._measure, this.tick, time);
    }
}

export class PauseEvent extends EventBase<PauseEvent> {

    assignMeasure(measure: number): number {
        return this.measure;
    }

    constructor(time: RecordingTime = performance.now(), duration : number = 0) {
        super(time);

        this.duration = duration;
    }

    process(index: number, events: RecordingEvent<any>[]): void {
    }

    clone(time: RecordingTime): PauseEvent {
        return new PauseEvent(time, this.duration);
    }
}

export class BreathEvent extends EventBase<BreathEvent> {

    readonly pressure: number;

    constructor(pressure: number, time: RecordingTime = performance.now()) {
        super(time);

        this.pressure = pressure;
    }

    process(index: number, events: RecordingEvent<any>[]): void {

        let nextChangeIndex = events.findIndex( (value, valueIndex) => {
            return (valueIndex > index)
                && (value instanceof BreathEvent);
        });

        if (nextChangeIndex != -1) {
            let nextBreath = events[nextChangeIndex] as BreathEvent;

            this._duration = nextBreath._startTime - this._startTime;
        }
    }

    clone(time: RecordingTime): BreathEvent {
        return new BreathEvent(this.pressure, time);
    }
}

export class ScoreEvent extends NoteBaseEvent<ScoreEvent> {

    constructor(note: number, _duration: number, time: RecordingTime = performance.now()) {
        super(note, time);
    }

    process(index: number, events: RecordingEvent<any>[]): void {
    }

    clone(time: RecordingTime): ScoreEvent {
        return new ScoreEvent(this.note, this._duration, time);
    }
}

export class KeyEvent extends EventBase<KeyEvent> {

    readonly keys: string;

    readonly uncType: UNCType;

    constructor(keys: string, uncType: UNCType, time: RecordingTime = performance.now()) {
        super(time);

        this.keys = keys;
        this.uncType = uncType;
    }

    process(index: number, events: RecordingEvent<any>[]): void {

        let nextChangeIndex = events.findIndex( (value, valueIndex) => {
            return (valueIndex > index)
                && (value instanceof KeyEvent);
        });

        if (nextChangeIndex != -1) {
            let nextEvent = events[nextChangeIndex] as KeyEvent;

            this._duration = nextEvent._startTime - this._startTime;
        }

    }

    clone(time: RecordingTime): KeyEvent {
        return new KeyEvent(this.keys, this.uncType, time);
    }
}

export class AnnotationEvent extends EventBase<AnnotationEvent> {

    readonly annotation: string;

    constructor(annotation: string, fromTime: RecordingTime = performance.now(), toTime: RecordingTime = performance.now() + 1000) {
        super(fromTime);

        this.annotation = annotation;
        this._duration = toTime - fromTime;
    }

    process(index: number, events: RecordingEvent<any>[]): void {
    }

    clone(time: RecordingTime): AnnotationEvent {
        return new AnnotationEvent(this.annotation, time, time + this._duration);
    }
}