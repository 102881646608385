/// <reference types="w3c-web-serial" />

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InstrumentConnection, InstrumentConnectionMode, InstrumentConnectionType } from './model/instrument-connection';
import { ConnectionService } from './services/connection.service';
import { HardwareSupportGuard } from './services/hardware-support-guard';
import { InstrumentComponentBase } from './helpers/instrument-component-base';
import { FlasherService } from './views/flasher/flasher.service';
import { FeatureSupportService } from './services/feature-support.service';
import { rootMain } from './helpers/log-config';
import { CATEGORY_LOG_CONTROL } from 'typescript-logging-category-style';
import { SerialSelectionService } from './services/electron/serial-selection.service';

const log = rootMain.getChildCategory("application");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends InstrumentComponentBase implements OnInit, AfterViewInit {

  title = 'EMEO Connect';

  constructor(
    private route: ActivatedRoute,
    public featureService: FeatureSupportService,
    private modalService: NgbModal,
    private hardwareSupport: HardwareSupportGuard,
    protected cs: ConnectionService,
    protected flasherService: FlasherService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    protected sss: SerialSelectionService,
    private router: Router) {

    super(cs);
  }

  async connectSerial() {
    log.info("User clicked serial connection");
    let connect = await this.cs.establishSerialConnection()
  }
  
  async disconnect() {
    log.info("User clicked disconnect connection");
    await this.cs.disconnect();
  }  

  get hasEMEOConnection(): boolean {
    // log.debug("hasEmeoConnection: " + this.hardwareSupport.bluetoothIsSupported);
    return this.cs.hasEMEOConnection; 
  }

  get showConnectButton(): boolean {
    return true; // (window.location.pathname == "/");
  }

  toggleMaintenanceMode(event: MouseEvent) {
    if (event.shiftKey) {
      this.featureService.maintenanceMode = !this.featureService.maintenanceMode
    }
  }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        let mode = (params.maintenance == 1 || params.maintenance == true ? true : false );
        this.featureService.maintenanceMode = mode;

        mode = (params.dev == 1 || params.dev == true ? true : false );
        this.featureService.developmentMode = mode;

        mode = (params.alpha == 1 || params.alpha == true ? true : false );
        this.featureService.alphaMode = mode;

        mode = (params.beta == 1 || params.beta == true ? true : false );
        this.featureService.betaMode = mode;

        if (params.feature) {
          if (Array.isArray(params.feature)) {
            for (let key of params.feature) {
              this.featureService.enableFeature(key);
            }
          }
          else {
            this.featureService.enableFeature(params.feature);
          }
        }

        if (params.hardware) {
          this.featureService.forceInstrumentType(params.hardware);
        }

        if (params.firmware) {
          this.featureService.forceFirmware(params.firmware);
        }

        if (window.isElectron) {
          window.ipcRenderer.send("window:setTitle", "EMEO Connect on Electron");

          window.ipcRenderer.on("serial:selectPort", (event, portList) => {
            this.sss.openSelectionDialog(portList);
          });
        }

        if (params.logging == 1 || params.logging == true) {
          CATEGORY_LOG_CONTROL().getProvider("EMEO-Web").update("debug")
        }
          
      });    
  }

  ngAfterViewInit(): void {
    this.hardwareSupport.serialIsSupported = ('serial' in navigator);
  }
 
  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    let target = '';

    if (!current || current.connectionMode === InstrumentConnectionMode.FLASH) {
      // Go to main screen if the instrument is gone
      target = '/';
    }
    else {
      if  (!this.flasherService.isFlasherOpen) {
        if (this.modalService.hasOpenModals()) {
          log.info("Closing modals");
          this.modalService.dismissAll();
        }
      }
      log.info("Moving to main screen");
      target = '/main';
    }

    target = '';

    if (target != '') {

      this.zone.run(() => {
        this.router.navigate([target], { queryParamsHandling: "preserve" });
      });
    }

    this.cd.detectChanges();
  }

  @ViewChild('fs') fs?: ElementRef;

  @HostBinding('class.is-fullscreen') isFullscreen = false;

  isActive = false;
  
  openFullscreen() {
    this.isFullscreen = true;
    const el = this.fs?.nativeElement;

    let doc: any = document;

    if (!doc.fullscreenElement) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } 
      else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } 
      else if (el.webkitRequestFullscreen) {
        // el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } 
      else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    }

    setTimeout(() => {
      this.isActive = true;
    }, 500);    
  }

  closeFullscreen() {
    this.isFullscreen = false;
    this.isActive =  false;
    
    let doc: any = document;

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } 
    else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    } 
    else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } 
    else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    }
  }

  onFullscreenChange(event: Event) {
    if (document.fullscreenElement) {
      this.isFullscreen = true;
      this.isActive =  true;
    }
    else {
      this.isFullscreen = false;
      this.isActive =  false;
    }
  }

  onFullscreenError(event: Event) {
    this.isFullscreen = false;
    this.isActive =  false;
  }

  get navigator(): any {
    return navigator;
  }
}
