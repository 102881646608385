import { Injectable } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedEmitter } from 'tiny-typed-emitter';
import { rootService } from '../helpers/log-config';

const log = rootService.getChildCategory("metronome");

export interface MetronomeEvents {
  'beat': (measure: number, beat: number) => void;
  'tick': (tick: number, denominator: number) => void;
  'count': () => void;
}

@Injectable({
  providedIn: 'root'
})
export class MetronomeService extends TypedEmitter<MetronomeEvents> {

  protected highSound: HTMLAudioElement;
  protected lowSound: HTMLAudioElement;

  constructor() { 
    super();

    let highAudio = new Audio("../../assets/sounds/Clave-High.wav");
    highAudio.load();
    this.highSound = highAudio;

    let lowAudio = new Audio("../../assets/sounds/Clave-Low.wav");
    lowAudio.load();

    this.lowSound = lowAudio;
  }

  private _measure: number = 0;

  get measure(): number {
    return this._measure;
  }

  private _beat: number = 0;

  private _tick: number = 0;

  private _totalTick: number = 0;

  get beat(): number {
    return this._beat;
  }

  get bpm(): number {
    return this._bpm;
  }

  private _beats: number = 4;
  private _unit: number = 4;
  private _bpm: number = 80;
  private _ticks: number = 4;

  // Define a beat. For a 3/4 beat, the beats value would be 3 and the unit would be 4. 
  prepareMetronome(beats: number = 4, unit: number = 4, tempo: number = 8, ticks: number = unit) {
    this._beats = beats;
    this._unit = unit;
    this._bpm = tempo;
    this._ticks = ticks;
    
    this._measure = 1;
    this._beat = 0;
    this._tick = 0;
  }


  public get isRunning() : boolean {
      return this._timer != null;
  }

  protected _audioActive : boolean = true;

  public set audioActive(flag : boolean) {
      this._audioActive = flag;
  }

  public get audioActive() : boolean {
      return this._audioActive;
  }

  public toggleAudio() : boolean {
      this._audioActive = !this._audioActive;
      return this._audioActive;
  }

  private _timer: Subscription | null = null;
  
  private tick(iteration: number) {

      this._tick++;
      this._totalTick++;

      var emitBeat = false;

      if (this._tick > (this._ticks / this._unit)) {
        this._tick = 1;
        this._beat++;
      
        emitBeat = true;
      }

      if (this._beat > this._beats) {
        this._beat = 1;
        this._measure++;

        emitBeat = true;
      }

      if (emitBeat) {
        this.emit('beat', this._measure, this._beat);

        // this.lowSound.play();

        if (this.audioActive) {
            if (this._beat === 1) {
              this.highSound.play();
            }
            else {
              this.lowSound.play();
            }
        }
      }

      if (this._measure > 0) {
        this.emit('tick', this._tick, this._ticks);
      }
  }
  
  private _running = new Subject<boolean>();

  get running$() : Subject<boolean> {
      return this._running;
  }

  get ticksPerMeasure() : number {
    return this._ticks;
  }
  
  get ticksPerMinute() : number {
    return (this._bpm / this._unit) * this._ticks;
  }

  start(countIn: boolean = false) {
      // do something here
      this._measure = (countIn ? 0 : 1);
      this._beat = 1;
      this._tick = 1;
      this._totalTick = 0;
  
      this._running.next(true);
      this._timer = interval(60000 / this.ticksPerMinute)
                      .pipe(takeUntil(this._running))
                      .subscribe(iteration => {
                        this.tick(iteration);
                      });
  }

  stop() {
    this._running.next(false);
    this._timer?.unsubscribe();
    this._timer = null;
  }
}


