import { ChargeState, InstrumentState } from './instrument-state';
import { TypedEmitter } from 'tiny-typed-emitter';
import { DeviceInfo, HardwareInfo, DEV_EMEO_SAX_V1_202, DEV_EMEO_CLARINET_V1, DEV_NO_DEVICE, InstrumentConnectionInfo, HARDWARE_INFOS, EMEO_SAX_V1 } from './device-info';
import { EmeoGlobalSettings, EmeoSetting, GlobalSettings } from './global-settings';
import { UNCDump, UNCRecord, UNCSettings, UNCType } from './uncsettings';
import { Subscription, timer } from 'rxjs';
import { ProgressObserver } from './progress-observer';
import { bin, hex } from '../helpers/helper';
import { Feature, FeatureSupportService } from '../services/feature-support.service';
import { rootConnection } from '../helpers/log-config';
import { sleep } from 'bossa-web';

const log = rootConnection.getChildCategory("connection");

export enum ConnectionState {
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error'
}

export interface InstrumentEvents {
  'statechange': (connection: InstrumentConnection, state: ConnectionState) => void;
  'updated-devicestate': (connection: InstrumentConnection, state: InstrumentState) => void;
  'sysex': (data: Uint8Array, receivedTime: number | undefined) => void;
  'midi': (data: Uint8Array, receivedTime: number | undefined) => void;
  'midi-message': (data: Uint8Array, receivedTime: number | undefined) => void;
  'keypress': (data: Uint8Array, receivedTime: number | undefined) => void;
  'unc': (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => void;
}

export interface InterfaceEvents {
  'data-received': (data: Uint8Array, receivedTime: number | undefined) => void;

  'disconnected': () => void;
}

export enum InstrumentConnectionMode {
  EMEO = 'EMEO',
  FLASH = 'FLASH'
}

export enum InstrumentConnectionType {
  DIRECT = 'DIRECT',
  MIDI = 'MIDI',
  SERIAL = 'SERIAL'
}

export class MidiTimeoutError extends Error {

  constructor(type: string) {
    super(`Timeout for message ${type}`);
  }
}

export interface KeyThreshold {
  threshold: number;
}

export interface KeyThresholds {
  [ key: number ] : KeyThreshold
}


export abstract class InstrumentInterface extends TypedEmitter<InterfaceEvents> {

  constructor() {
    super();
  }

  abstract open() : Promise<void>;
  
  abstract sendMessage(data: number[]): Promise<void>;

  abstract equals(connection: InstrumentInterface): boolean;

  abstract get type(): InstrumentConnectionType;

  abstract get mode(): InstrumentConnectionMode;

  abstract get port(): SerialPort;

  abstract enableFlashMode() : Promise<void>;
  
  abstract close() : Promise<void>;
}


export class MidiInvalidMessageError extends Error {

  constructor(type: string, data: number[] | Uint8Array | undefined) {
    super(`Invalid MIDI message ${type} [${data}]`);
  }
}

const NO_STATE: InstrumentState = <InstrumentState> {
  batteryPercent: 0,
  bleConnected: false,
  usbPowered: false,

  glitchLearnEnabled: false,
  glitchReducerEnabled: false,
  uncEnabled: false,
  overblowEnabled: false,
  playWithoutBlowingEnabled: false,

  outputKSRSysexEnabled: false,
  outputKeyStrokeSysexEnabled: false
};


export class InstrumentConnection 
                extends TypedEmitter<InstrumentEvents> 
                implements InstrumentConnectionInfo {

  protected canSend: boolean = false;

  protected settings: GlobalSettings;

  private _state: ConnectionState = ConnectionState.LOADING;

  get state() : ConnectionState {
    return this._state;
  }
  
  get connectionMode(): InstrumentConnectionMode {
    return this._connection.mode;
  }

  get connectionType(): InstrumentConnectionType {
    return this._connection.type;
  }

  get connection(): InstrumentInterface {
    return this._connection;
  }

  public blockRefresh : boolean = false;

  async disconnect() {
    this._connection.close();
  }

  constructor(
    private _connection: InstrumentInterface,
    private _fs: FeatureSupportService) {

    super();

    this.settings = new GlobalSettings();

    this._connection.on('data-received', this.messageReceived.bind(this));
    this._connection.on('disconnected', this.instrumentDisconnected.bind(this));

    if (this._connection.mode === InstrumentConnectionMode.EMEO) {
      this.internalPopulateDeviceInfos();
    }
  }

  private instrumentDisconnected() {
    this.emit('statechange', this, ConnectionState.ERROR);

    this.disconnect();
  }

  async refreshInstrumentInfo() {
    this.internalPopulateDeviceInfos();
  }

  private internalPopulateDeviceInfos(retryCount: number = 2) {

    this.emit('statechange', this, ConnectionState.LOADING);

    this
      .loadDeviceInfo()
      .then( (info: DeviceInfo) => {
        this.setDeviceInfo(info);

        this.fetchDeviceState();

        this.emit('statechange', this, ConnectionState.READY);
      })
      .catch ((e) => {
        if (retryCount > 0) {
          let updater = timer(2000);
          updater.subscribe( v => this.internalPopulateDeviceInfos(retryCount - 1));
        }
        else {
          this.setDeviceInfo(DEV_EMEO_SAX_V1_202);
        }
      });
  }

  public async fetchDeviceState() {
    
    this
      .loadDeviceState()
      .catch ((e) => {
        log.debug("Error fetching state", e);
        this.changeState(ConnectionState.ERROR);
        throw e;
      })
  }

  private changeState(newState: ConnectionState) {
    if (this._state !== newState) {
      this._state = newState;
      this.emit('statechange', this, newState);

      if (newState === ConnectionState.ERROR) {
        this.setDeviceState(NO_STATE);
      }
    }
  }

  isSameConnection(connection: InstrumentInterface): boolean {
    return this._connection.equals(connection);
  }  

  private _deviceState: InstrumentState = NO_STATE;

  get deviceState() : InstrumentState {
    return this._deviceState;
  }

  private setDeviceState(state: InstrumentState) {
    this._deviceState = state;
    this.checkState();

    this.emit('updated-devicestate', this, state);
  }

  private _deviceInfo: DeviceInfo = DEV_NO_DEVICE;

  get deviceInfo() : DeviceInfo {
    // if (this._deviceInfo === NO_DEVICE) {
    //   this.loadDeviceInfo().then( info => {
    //     this.setDeviceInfo(info);
    //   })
    // }
    return this._deviceInfo;
  }

  private setDeviceInfo(info: DeviceInfo) {
    this._deviceInfo = info;

    if (this._deviceInfo === DEV_EMEO_SAX_V1_202) {
      this.maxUNCAddress = 480;
    }
    
    this.checkState();

    log.debug(JSON.stringify(info));
  }

  private checkState() {
    if (this._deviceInfo !== DEV_NO_DEVICE) {
      if ((this._deviceState !== NO_STATE) || (this._deviceInfo === DEV_EMEO_SAX_V1_202) || (this._deviceInfo === DEV_EMEO_CLARINET_V1)) {
        this.changeState(ConnectionState.READY);
      }
    }
  }

  isValidHeader(data: Uint8Array, header: number[] | Uint8Array) : boolean {

    var validHeader = false;

    if (data.length >= header.length) {
      validHeader = true;

      for (var i = 0; i < header.length; i++) {
        if (data[ i ] !== header[ i ]) {
          validHeader = false;
        }
      }
    }

    return validHeader;
  }

  private NO_HANDLER: () => void = () => { throw new MidiTimeoutError(""); }

  public onceSysexWithHeader(header: number[] | Uint8Array, handler: (data: Uint8Array, receivedTime: number | undefined) => void, timeoutHandler: () => void = () => this.NO_HANDLER, timeout: number | undefined = undefined) {

    let self = this;
    let to = timeout || 5000;

    var listener: (data: Uint8Array, receivedTime: number | undefined) => void;

    let startedAt = Date.now();

    log.debug("Sysex with timeout " + to + " started at " + startedAt);

    // let watchdog = timer(to);
    let watchDogSubscription: Subscription = timer(to).subscribe( x => {
      log.debug("Sysex with timeout " + to + " ran into timeout after " + (Date.now() - startedAt));
      self.removeListener('sysex', listener);
      timeoutHandler();
    } );

    listener = (data: Uint8Array, receivedTime: number | undefined) => {

      log.debug("Received data from sysex with timeout " + to + " after " + (Date.now() - startedAt));
      log.debug("Data: " + hex(data));
      if (this.isValidHeader(data, header)) {
        log.debug("Received correct data from sysex, cleaning subscription");
        watchDogSubscription.unsubscribe();
        self.removeListener('sysex', listener);
        handler(data, receivedTime);
      }
      else {
        // log.debug(data);        
      }
    }

    this.addListener('sysex', listener);   
  }

  private async internalLoadDeviceInfo(observer: ProgressObserver | undefined = undefined) : Promise<DeviceInfo> {

    return new Promise((resolve, reject) => {
    
      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x60, 0x00, 0x00 ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 25) {
            var settings = Object.assign({}, DEV_NO_DEVICE);
            this.parseDeviceInfo(data, settings);

            resolve(settings);
          }
          else {
            reject(new MidiInvalidMessageError("DEVICE-INFO", data));
          }
        }, 
        () => {
          reject( new MidiTimeoutError("DEVICE INFO") );
        });

        this.emit('statechange', this, ConnectionState.LOADING);
        this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x60, 0x00, 0x00, 0xf7]);
    });    
  }

  private verifyHardwareInfo(info: DeviceInfo): boolean {

    let hwTypes = HARDWARE_INFOS.filter( (hw) => { return (info.hardware.type === hw.type) });
  
    if (hwTypes.length > 0) {

      if (hwTypes.find( (hw) => { return hw.version === info.hardware.version})) {
        return true;
      }

      info.hardware.version = EMEO_SAX_V1.version;
      return false;
    }

    // Patch data to match at least EMEO SAX 1
    info.hardware.type = EMEO_SAX_V1.type
    info.hardware.version = EMEO_SAX_V1.version

    return false;
  }

  async loadDeviceInfo(observer: ProgressObserver | undefined = undefined) : Promise<DeviceInfo> {

    let deviceInfo = await this.internalLoadDeviceInfo(observer);
  
    if (!this.verifyHardwareInfo(deviceInfo)) {
      // If the verification found an error, we will patch the instrument
      // to ensure this will not happen again.
      await this.sendHardwareInfo(deviceInfo.hardware);
    }

    return new Promise(resolve => {

      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x13, 0x61, 0x00, 0x00 ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 34) {
            this.parseDeviceUniqueId(data, deviceInfo);
            // this.emit('statechange', this, ConnectionState.READY);
            resolve(deviceInfo);
          }
          else {
            // this.emit('statechange', this, ConnectionState.READY);
            throw new MidiInvalidMessageError("UNIQUE-ID", data);
          }
        },
        () => {
          // this.emit('statechange', this, ConnectionState.READY);
          throw new MidiTimeoutError("UNIQUE-ID");
        },
        2000);

      this.emit('statechange', this, ConnectionState.LOADING);
      this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x61, 0x00, 0x00, 0xf7]);
    });
  }
  
  async loadDeviceState(observer: ProgressObserver | undefined = undefined) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x62, 0x00, 0x00, 0xf7]);
  }

  async loadGlobalSettings(observer: ProgressObserver | undefined = undefined) : Promise<GlobalSettings> {

    return new Promise((resolve, reject) => {

      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x00 ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 32) {
            var settings = new GlobalSettings();
            this.parseGlobalSettings(data, settings);
            resolve(settings);
          }
          else {
            reject(new MidiInvalidMessageError("GLOBAL-SETTINGS", data));
          }
        },
        () => {
          reject(new MidiTimeoutError("GLOBAL SETTINGS"));
        });

        this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x40, 0x00, 0x00, 0xf7]);
    });
  }

  async loadUNCTable(observer: ProgressObserver | undefined = undefined) : Promise<UNCSettings> {

    await this.stopKeystrokeMessages();

    let uncTableName: string = await new Promise(resolve => {

      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x63, 0x00, 0x00 ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 30) {

            var name = '';
            for (var i = 0; i < 20 && data[0xa + i] != 0; i++) {
              name = name + String.fromCharCode(data[ 0xa + i ]);
            }
        
            log.debug("Getting UNC table " + name);
            resolve(name);
          }
          else {
            throw new MidiInvalidMessageError("UNC-TABLE-NAME", data);
          }
        },
        () => {
          resolve('Default-Table');
        });

        this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x63, 0x00, 0x00, 0xf7]);
    });

    const data: UNCSettings = await new Promise(resolve => {

      let settings = new UNCSettings();

      settings.name = uncTableName;
      
      settings.uncDump = new UNCDump(this.deviceState?.maximumUNCRecords || 200);
      settings.ksrDump = new UNCDump(this.deviceState?.maximumKSRRecords || 380);

      let totalRecords = settings.uncDump.maxRecords + settings.ksrDump.maxRecords;

      let index = 0;

      let dump = settings.uncDump;

      let listener = (data: Uint8Array, receivedTime: number | undefined) => {

        if (this.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x50 ]) && (data.length >= 19)) {
          let [ record, lastRecord ] = this.parseUNCRecord(data, dump)
          index++;
          
          if (index >= dump.maxRecords) {
            dump = settings.ksrDump;
          }

          if (lastRecord) {
            this.removeListener("sysex", listener);
            if (observer?.progress) observer?.progress(totalRecords, totalRecords);
            
            log.debug("Finished loading UNC table");
            this.startKeystrokeMessages().then( () => {resolve(settings); } );
          }
          else {
            if (observer?.progress) observer?.progress(index, totalRecords);
          }
        }
      };

      this.addListener("sysex", listener);
      this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x50, 0x00, 0x00, 0xf7]);
    });

    return data;
  }

  private pushBits(bits: string, message: number[]): number[] {

    if ((bits.length % 7) != 0) {
      throw Error('Invalid bits string length (' + bits.length + ')');
    }

    for (var i = 0; i < bits.length; i+= 7) {
      message.push(Number.parseInt(bits.substr(i, 7), 2));
    }

    return message;
  }

  async sendUNCRecord(address: number, record: UNCRecord) {

    let state = Number.parseInt(record.keyState, 2);

    // 0x0a    0x0b    0x0c    0x0d    0x0e    0x0f    0x10    0x11    0x12    0x13
    // 6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210
    //       R.RRRRRRR.IIIIIII.IPPPPPP.PPPPPPP.PPPTTTT.TTTTkkK.KKKKKKK.KKKKKKK.KKKKKKK Before 4.2v5
    //       R.RRRRRRR.bbkkIII.IPPPPPP.PPPPPPP.PPPTTTT.TTTTKKK.KKKKKKK.KKKKKKK.KKKKKKK Starting 4.2v5

    let newLayout = true; // TODO;: this._fs.can({ connection: this }, Feature.AdditionalKeys);

    let bits = '000000';

    if (newLayout) {
      bits += bin(record.attackDelay, 8);
      bits += bin(record.bank, 2);
      bits += bin(record.recordType, 2);
      bits += bin(record.resistance, 4);
      bits += bin(record.pitchShift + 8192, 16);
      bits += bin(record.parameterType, 8);
      bits += record.keyState.substring(0, 24).padStart(24, '0');
    }
    else {
      bits += bin(record.attackDelay, 8);
      bits += bin(record.resistance, 8);
      bits += bin(record.pitchShift + 8192, 16);
      bits += bin(record.parameterType, 8);
      bits += bin(record.recordType, 2);
      bits += record.keyState.substring(0, 22).padStart(22, '0');
    }

    let message = [
      0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12,
      0x50, // UNC Settings
      (address >> 7 & 0x7f), (address & 0x7f)
    ]

    message = this.pushBits(bits, message);
    message.push( 0xf7 );

    this.sendMessage(message);
  }

  async sendGlobalSettings(settings: GlobalSettings, observer: ProgressObserver | undefined = undefined) {

    let message = [
      0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12,
      0x40, // Global Settings
      0x00, 0x00, // Full set
    ]

    for (var i = 0; i < EmeoGlobalSettings.getSize(); i++) {
      message.push( 0x7f );
    }

    for(var key of Object.keys(EmeoGlobalSettings)) {
      if (key == "getSetting") {
        continue;
      }

      if (key == "getSize") {
        continue;
      }

      let emeoSetting: EmeoSetting = (<any>EmeoGlobalSettings)[key];
      let offset = 0x0a + emeoSetting.code;

      message[ offset ] = (<any>settings)[key];
    }

    message.push( 0xf7 );

    this.sendMessage(message);
  }

  private messageReceived(data: Uint8Array, receivedTime: number | undefined = undefined) : void {
    
    log.debug("Received: " + hex(data));
    
    if (data[0] == 0xf0) {
      
      if (this.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x13 ]) && ((data[0x7] & 0x7e) == 0)) {
        this.emit('keypress', data, receivedTime);
      }
      else {
        // This is the device state SysEx message
        if (this.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x13, 0x62, 0x00, 0x00 ])) {
          if (data.length >= 27) {
            var state = this.parseDeviceState(data);
            this.setDeviceState(state);
          }
        }
        // This is another SysEx message
        else if (this.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x50 ])) {
          let [ record ] = this.parseUNCRecord(data);
          this.emit('unc', record, true, receivedTime);
        }       
        else if (this.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x13, 0x50 ])) {
          let [ record ] = this.parseUNCRecord(data);
          this.emit('unc', record, false, receivedTime);
        }       

        this.emit("sysex", data, receivedTime);
      }
    }
    else {
      this.emit("midi", data, receivedTime);
    }

    this.emit('midi-message', data, receivedTime);
  }

  async sendHardwareInfo(hardwareInfo: HardwareInfo) {

    // 0x0a 0x0b 0x0c                          0x16 0x17 0x18
    // HT   HV   S0 S1 S2 S3 S4 S5 S6 S7 S8 S9 DD   MM   YY   f7

    let message = [
      0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12,
      0x60, // Device Info
      0x00, 0x00,
      (hardwareInfo.type),
      (hardwareInfo.version)
    ]
    
    for (var i = 0; i < 10; i++) {
      if (i < hardwareInfo.serialNumber.length) {
        message.push( hardwareInfo.serialNumber.charCodeAt(i));
      }
      else {
        message.push( 0 );
      }
    }

    let date = new Date(hardwareInfo.deviceDate);
    if (date.getTime()  == 0) {
      message.push( 0x00, 0x00, 0x00 ); 
    }
    else {
      message.push( date.getDate(), date.getMonth(), date.getFullYear() - 2000);
    }

    message.push( 0xf7 );

    this.sendMessage(message);
  }

  async rebootWithHibernate() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x0a, 0xf7]);
  }
  
  async rebootWithoutHibernate() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x0b, 0xf7]);
  }

  async clearGlitchTable() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x7f, 0xf7]);
  }

  async clearUNCTable() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x7e, 0xf7]);
  }

  async calibrateSensor() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x41, 0xf7]);
  }

  async enableAdvancedMode() {
    await this.setupAdvancedMode(true);
  }

  async disableAdvancedMode() {
    await this.setupAdvancedMode(false);
  }

  async setupAdvancedMode(enabled: boolean) : Promise<void> {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x45 : 0x44), 0xf7]);
  }

  async enableBM20FirmwareMode() {
    await this.setupBM20FirmwareMode(true);
  }

  async disableBM20FirmwareMode() {
    await this.setupBM20FirmwareMode(false);
  }

  async setupBM20FirmwareMode(enabled: boolean) : Promise<void> {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x21, 0x00, 0x00, (enabled ? 0x01 : 0x00), 0xf7]);
  }

  private _keystrokeMessagesCount = 0;

  async startKeystrokeMessages() {
    await this.internalKeystrokeMessages(true);
  }

  async stopKeystrokeMessages() {
    await this.internalKeystrokeMessages(false);
  }

  async setupKeystrokeMessage(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x51 : 0x50), 0xf7]);
  }

  private internalKeystrokeMessages(enable: boolean) : Promise<void> {
    return this.setupKeystrokeMessage(enable);
  }


  private _playWithoutBlowCount = 0;

  async startPlayWithoutBlow() {
    this.internalPlayWithoutBlow(true);
  }

  async stopPlayWithoutBlow() {
    this.internalPlayWithoutBlow(false);
  }

  async setupPlayWithoutBlow(enabled: boolean) {
    this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x31 : 0x30), 0xf7]);
  }

  private internalPlayWithoutBlow(enable: boolean) {

    this._playWithoutBlowCount += (enable ? 1 : -1);
    this._playWithoutBlowCount = Math.max(this._playWithoutBlowCount, 0);

    if (this._playWithoutBlowCount <= 1) {
      this.setupPlayWithoutBlow(this._playWithoutBlowCount == 1);
    }
  }

  async startUNCMessages() {
    await this.internalUNCMessages(true);
  }

  async stopUNCMessages() {
    await this.internalUNCMessages(false);
  }
  
  async startCCMessages() {
    await this.internalCCMessages(true);
  }

  async stopCCMessages() {
    await this.internalCCMessages(false);
  }

  async setupUNCMessages(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x61 : 0x60), 0xf7]);
  }
  
  async setupCCMessages(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x3b : 0x3a), 0xf7]);
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, (enabled ? 0x3d : 0x3c), 0xf7]);
  }

  // TODO: Restart EMEO 	F0 00 21 43 00 00 12 40 00 0E 0B F7

  async enterNinaFlasher() : Promise<void>{

    return new Promise( async (resolve, reject) => {

      this.blockRefresh = true;
      await sleep(1000);

      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x01, 0xf7 ],
        (data: Uint8Array, receivedTime: number | undefined) => {
          this.blockRefresh = false;
          resolve();
        },
        () => {
          this.blockRefresh = false;
          reject(new MidiTimeoutError("NINA-Mode"));
        }, 20000);

        this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x00, 0xf7] );
    });
  }

  private _UNCMessagesCount = 0;

  private async internalUNCMessages(enable: boolean) {
    await this.setupUNCMessages(enable);
  }

  private async internalCCMessages(enable: boolean) {
    await this.setupCCMessages(enable);
  }

  async setupGlitchReducer(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x17, (enabled ? 1 : 0), 0xf7]);
  }

  async setupGlitchLearn(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x16, (enabled ? 1 : 0), 0xf7]);
  }

  async setupUNCMode(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0f, (enabled ? 1 : 0), 0xf7]);
  }

  async stopConditionalUNCMessages() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x62, 0xf7]);
  }

  async startConditionalUNCMessages() {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x0e, 0x63, 0xf7]);
  }

  async setupOverblowMode(enabled: boolean) {
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x10, (enabled ? 1 : 0), 0xf7]);
  }

  async blinkDevice(color: 'red'|'green'|'orange', times: number, speed: number) {
    let cl = color == 'red' ? 1 : (color == 'green' ? 0 : 2);
    let cn = times;
    let sp = speed; 
    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x65, 0x00, 0x00, cl, cn, sp, 0xf7]);
  }

  private parseKeyThresholds(data: number[] | Uint8Array) : KeyThresholds {

    let result: KeyThresholds = {}
  
    for (var i = 0; i < 24; i++) {
      let threshold = ((data[ 0x0a + 2 * i ] & 0x01) << 7) | (data[ 0x0a + 2 * i + 1] & 0x7f)

      result[ i ] = { threshold: threshold }
    }
   
    console.log(result);

    return result
  }

  async loadKeyThresholds(observer: ProgressObserver | undefined = undefined) : Promise<KeyThresholds> {

    return new Promise((resolve, reject) => {
    
      this.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x67, 0x00, 0x00 ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 59) {
            let thresholds = this.parseKeyThresholds(data);

            resolve(thresholds);
          }
          else {
            reject(new MidiInvalidMessageError("KEY-THRESHOLDS", data));
          }
        }, 
        () => {
          reject( new MidiTimeoutError("KEY THRESHOLDS") );
        });

        this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x11, 0x67, 0x00, 0x00, 0xf7 ]);
    });    
  }

  async sendKeyThreshold(keyId: number, threshold: number) {
    
    let key = keyId & 0x7f
    let num = threshold & 0xff
    let th = num >> 7;
    let tl = num & 0x7f;

    await this.sendMessage( [0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x67, 0x00, 0x00, key, th, tl, 0xf7]);
  }

  async sendMessage(data: number[]) {
    log.trace("Sending: " + hex(data));
    try {
      await this._connection.sendMessage(data);
    }
    catch (e) {
      log.error("Failed sending message", e);
      this.changeState(ConnectionState.ERROR);
    }
  }

  private parseBits(bits: string, length: number, offset: number = 0): [ string, number ] {

    let value = Number.parseInt(bits.substring(0, length), 2) - offset;
    bits = bits.substring(length);

    return [ bits, value ];
  }

  private parseUNCRecord(data: number[] | Uint8Array, dump?: UNCDump) : [ UNCRecord, boolean ] {

    let record = new UNCRecord();

    let address = data[0x08] << 7 | data[0x09];

    // 7F 7F indicates an invalid key stroke
    if ((data[0x08] == 0x7f) && (data[0x09] == 0x7f)) {
      record.type = UNCType.INVALID;
    }
    else {
      // between 00 00 and 00 C8 is a user configured keys
      record.type = UNCType.USER;

      // Above 00 C8 is a factory key 
      if (address >= 0xc8) {
        record.type = UNCType.FACTORY;
      }
    }

    // a UNC sysex record contains 64 bit structure
    // RR = 8 bits  = keystroke attack delay(anti glitch)
    // II = 8 bits  = intensity - lower value means higher impact eg. 0x0007 = sensitivity * 0.7, 0x000A = sensitivity * 1.0
    // PP = 16 bits = pitchshift 8192 = no shift  0x0000 = -8192  0x3FFF = +8192
    // TT = 8 bits  = parameter type
    // KK = 24 bits = keyState - last 2 bits (which are not used for hall-sensors) are used to set recordtype  00 = note, 01 = Menu, 10, Note config, 11 = user defined note
    // uu = Unused
    //
    // 0x0a    0x0b    0x0c    0x0d    0x0e    0x0f    0x10    0x11    0x12    0x13
    // 6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210.6543210
    //       R.RRRRRRR.IIIIIII.IPPPPPP.PPPPPPP.PPPTTTT.TTTTkkK.KKKKKKK.KKKKKKK.KKKKKKK Before 4.2v5
    //       R.RRRRRRR.uukkIII.IPPPPPP.PPPPPPP.PPPTTTT.TTTTKKK.KKKKKKK.KKKKKKK.KKKKKKK Starting 4.2v5

    let bits = "";
    for (var i = 0xa; i < 0x14; i++) {
      bits += bin(data[i], 7);
    }


    let newLayout = this._fs.can({ connection: this }, Feature.AdditionalKeys);

    if (newLayout) {
      var unused = 0;
  
      [ bits ] = this.parseBits(bits, 6);
      [ bits, record.attackDelay ] = this.parseBits(bits, 8);
      [ bits, record.bank ] = this.parseBits(bits, 2);
      [ bits, record.recordType ] = this.parseBits(bits, 2);
      [ bits, record.resistance ] = this.parseBits(bits, 4);
      [ bits, record.pitchShift ] = this.parseBits(bits, 16, 8192);
      [ bits, record.parameterType ] = this.parseBits(bits, 8);
      record.keyState = bits.substring(0, 24).padStart(24, '0');;
    }
    else {
      [ bits ] = this.parseBits(bits, 6);
      [ bits, record.attackDelay ] = this.parseBits(bits, 8);
      [ bits, record.resistance ] = this.parseBits(bits, 8);
      [ bits, record.pitchShift ] = this.parseBits(bits, 16, 8192);
      [ bits, record.parameterType ] = this.parseBits(bits, 8);
      [ bits, record.recordType ] = this.parseBits(bits, 2);
      record.keyState = bits.substring(0, 22).padStart(22, '0');;      
    }

    if (dump) {
      dump.recordCount++;
      dump.records[ address ] = record;
    }

    return [ record, (address == this.maxUNCAddress) ];
  }

  private maxUNCAddress: number = 480;

  public parseKeyRecord(data: number[] | Uint8Array) : string {

    // a keypress record contains 22 bit structure
    //
    // 0x07    0x08    0x09    0x0a
    // 6543210.6543210.6543210.6543210
    //       K.KKKKKKK.KKKKKKK.KKKKKKK

    let keyState = Number(((data[0x7] & 0x01) << 21) | (data[0x08] << 14) | (data[0x09] << 7) | (data[0x0a])).toString(2).padStart(22, '0');

    return keyState;
  }

  private parseDeviceState(data: number[] | Uint8Array) : InstrumentState {

    // 0x0a BA = Battery voltage  0x00 = low   0x7f = high
    // 0x0b UB = isPoweredByUSB
    // 0x0c BT = BLE.connected()
    // 0x0d x1 = isUNCmodeEnabled_
    // 0x0e x2 = isGlitchReducerEnabled_
    // 0x0f x3 = isGlitchLearnEnabled_
    // 0x10 x4 = isOverblowEnabled_
    // 0x11 x5 = mustPlayWithoutBlowing_
    // 0x12 x6 = outputKSRmidiSysEx_ (UNC table keystroke result)
    // 0x13 x7 = outputKeyStrokeSysEx_ (22bit hall sensor)
    // 0x14 x8 = outputKSRWhenBlowLongEnough_
    // 0x15 U1 = MSB Maximum UNC records
    // 0x16 U2 = LSB Maximum UNC records
    // 0x17 F1 = MSB Maximum KSR records
    // 0x18 F2 = LSB Maximum KSR records
    // 0x19 BB = UNC Selected Bank number 
    //           0x00 = Global Bank, 
    //           0x01 = Subset01, 
    //           0x02 = Subset02,
    //           0x03 = Subset03
    // 0x1A CS = Charge Status flag  
    //           NOT_CHARGING 0x00
    //           PRE_CHARGING 0x10 
    //           FAST_CHARGING 0x20 
    //           CHARGE_TERMINATION_DONE 0x30
    // 0x1B    = Advanced Mode ON (1) or OFF (0) 
    // 0x1C    = BM20 Firmware Flash Mode ON (1) or OFF (0) 

    let hasUNCBanks = this._fs.can({ connection: this }, Feature.UNCBanks);
    let hasPowerInfo = this._fs.can({ connection: this }, Feature.ExtendedPowerInfo);
    let hasAdvancedMode = this._fs.can({ connection: this }, Feature.AdvancedFeatureSwitch);

    let result = <InstrumentState>{
      batteryPercent: (data[0x0a] / 127.0),
      usbPowered: (data[0x0b] == 1),
      bleConnected: (data[0x0c] == 1),

      uncEnabled: (data[0x0d] == 1),
      glitchReducerEnabled: (data[0x0e] == 1),
      glitchLearnEnabled: (data[0x0f] == 1),
      overblowEnabled: (data[0x10] == 1),
      playWithoutBlowingEnabled: (data[0x11] == 1),

      outputKSRSysexEnabled: (data[0x12] == 1),
      outputKeyStrokeSysexEnabled: (data[0x13] == 1),
      outputConditionalKeyStrokeSysexEnabled: (data[0x14] == 1),

      maximumUNCRecords: ((data[0x15] & 0x7f) << 7) | (data[0x16] & 0x7f),
      maximumKSRRecords: ((data[0x17] & 0x7f) << 7) | (data[0x18] & 0x7f),

      uncBank: (hasUNCBanks ? data[0x19] : 0x00),
      chargeState: <ChargeState> (hasPowerInfo ? data[0x1a] : ChargeState.NO_STATE ),

      advancedMode: (hasAdvancedMode ? data[0x1b] : false ),
      bm20FirmwareMode: (hasAdvancedMode ? data[0x1c] : false )
    };

    this.maxUNCAddress = result.maximumKSRRecords + result.maximumUNCRecords;

    return result;
  }

  private parseDeviceInfo(data: number[] | Uint8Array, info: DeviceInfo) {

    var serial = '';
    for (var i = 0; i < 10 && data[0xc + i] != 0; i++) {
      serial = serial + String.fromCharCode(data[ 0xc + i ]);
    }
    
    var date = new Date(0);
    date.setFullYear( 2000 + data[0x18]);
    date.setMonth(data[0x17] - 1, data[0x16]);

    var hwType = data[0xa];
    var hwVersion = data[0xb];
    
    if (this._fs.overidingInstrumentType) {
      var instrumentType = this._fs.overidingInstrumentType;
      hwType = instrumentType.type;
      hwVersion = instrumentType.version;
    }

    if (info.hardware) {
      info.hardware.type = hwType;
      info.hardware.version = hwVersion;
      info.hardware.serialNumber = serial;
      info.hardware.deviceDate = date;
    } 
    else {
      info.hardware = new HardwareInfo(hwType, hwVersion, serial, '', date);
    }
  }

  private parseDeviceUniqueId(data: number[] | Uint8Array, info: DeviceInfo) {

    var serial = '';
    let bits = ''

    for(var i = 0; i < 20; i++) {
      bits = bin(data[ 29 - i], 7) + bits;

      if (bits.length >= 8) {
        let nibble = bits.substring(bits.length - 8);
        bits = bits.substring(0, bits.length - 8);
        serial = Number.parseInt(nibble, 2).toString(16).padStart(2,'0') + serial;
      }
    }

    serial = serial.substring(serial.length - 32, 32).toUpperCase();

    info.hardware.circuitSerialNumber = serial;

    // We need to distinguish between the 'old' version format 
    // and the new.
    
    var oldFormat = false;

    // Dot at second position?
    oldFormat = oldFormat || (data[0x1e + 1] === '.'.charCodeAt(0));
    oldFormat = oldFormat || (data[0x1e] == 52); 

    var version = '';

    if (oldFormat) {
      for (var i = 0; i < 4 && data[0x1e + i] != 0; i++) {
        version = version + String.fromCharCode(data[ 0x1e + i ]);
      }      
    }
    else {
      version = Number(data[0x1e]).toString() + "." 
              + Number(data[0x1f]).toString() + "." 
              + Number(data[0x20]).toString() + "." 
              + Number(data[0x21]).toString();
    }

    if (this._fs.overridingFirmware) {
      version = this._fs.overridingFirmware;
    }

    info.firmwareVersion = version;

    if (data.length >= 37) {
      let ninaVersion = Number(data[0x22]).toString() + "." 
                      + Number(data[0x23]).toString() + "." 
                      + Number(data[0x24]).toString();

      info.ninaVersion = ninaVersion;
    }
  }  
  
  parseGlobalSettings(data: number[] | Uint8Array, settings: GlobalSettings) {

    for(var key of Object.keys(EmeoGlobalSettings)) {
      if (key == "getSetting") {
        continue;
      }

      if (key == "getSize") {
        continue;
      }

      let emeoSetting: EmeoSetting = (<any>EmeoGlobalSettings)[key];
      let offset = 0x0a + emeoSetting.code;
      let value = data[ offset ];

      if (value !== undefined) {
        log.debug("Setting '" + key + "' = "+ value);

        if (emeoSetting.validate(value)) {
          (<any>settings)[key] = value;
        } 
        else {
          log.debug(`Invalid value ${value} for setting ${emeoSetting.code}: ${emeoSetting.name}`);
          (<any>settings)[key] = emeoSetting.default;
        }
      }
      else {
        log.debug("No data for " + key + " is " + JSON.stringify(emeoSetting));
      }
    }
  }
}
