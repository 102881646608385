import { FeatureSupportService } from './services/feature-support.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { HardwareSupportGuard } from './services/hardware-support-guard';
import { FirmwareService } from './services/firmware.service';

import { TapeViewComponent } from './views/learn/tape-view/tape-view.component';
import { TapeSliceComponent } from './views/learn/tape-slice/tape-slice.component';

import { WelcomeComponent } from './views/welcome/welcome.component';
import { MainComponent } from './views/main/main.component';
import { InstrumentComponent } from './views/instrument/instrument.component';
import { SettingsComponent } from './views/settings/settings.component';
import { BackupComponent } from './views/backup/backup.component';
import { UserDefinedKeysComponent } from './views/user-defined-keys/user-defined-keys.component';
import { FlasherComponent } from './views/flasher/flasher.component';
import { BackupRestoreComponent } from './views/backup-restore/backup-restore.component';
import { BackupListComponent } from './views/backup-list/backup-list.component';
import { DeviceStatusComponent } from './views/device-status/device-status.component';
import { BackupInfoComponent } from './views/backup-info/backup-info.component';
import { ConfirmationDialogComponent } from './views/confirmation-dialog/confirmation-dialog.component';
import { RestoreComponent } from './views/restore/restore.component';
import { VersionComponent } from './views/version/version.component';
import { FooterComponent } from './views/footer/footer.component';
import { ProgressComponent } from './views/progress/progress.component';
import { DeviceSummaryComponent } from './views/device-summary/device-summary.component';
import { NoteComponent } from './views/user-defined-keys/note/note.component';
import { NoteConfigComponent } from './views/user-defined-keys/note-config/note-config.component';
import { KeyPickerComponent } from './views/user-defined-keys/key-picker/key-picker.component';
import { ChangeLogComponent } from './views/change-log/change-log.component';
import { InstrumentConfigComponent } from './views/instrument-config/instrument-config.component';
import { PlayasongComponent } from './views/learn/playasong/playasong.component';
import { ScoreComponent } from './views/learn/score/score.component';
import { CommonModule } from '@angular/common';
import { TunerComponent } from './views/learn/tuner/tuner.component';
import { VolumeMeterComponent } from './views/learn/volume-meter/volume-meter.component';
import { VisualizerComponent } from './views/learn/visualizer/visualizer.component';
import { ScoreDialogComponent } from './views/learn/score-dialog/score-dialog.component';
import { TapeDialogComponent } from './views/learn/tape-dialog/tape-dialog.component';
import { ListenerControlComponent } from './views/learn/listener-control/listener-control.component';
import { DurationPipe } from './pipes/duration.pipe';
import { SupportRequestComponent } from './views/support-request/support-request.component';
import { ReportComponent } from './views/report/report.component';
import { TuningMarkerComponent } from './svg/tuning-marker/tuning-marker.component';
import { PitchIndicatorComponent } from './svg/pitch-indicator/pitch-indicator.component';
import { PressureIndicatorComponent } from './svg/pressure-indicator/pressure-indicator.component';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';
import { DynamicComponentDirective } from './dynamic-component.directive';
import { FingeringModalComponent } from './views/fingering-modal/fingering-modal.component';
import { InstrumentDiagramComponent } from './views/instrument-diagram/instrument-diagram.component';
import { FeatureSelectorComponent } from './views/feature-selector/feature-selector.component';
import { RecoveryModeComponent } from './views/recovery-mode/recovery-mode.component';
import { SensorsComponent } from './views/sensors/sensors.component';


import { EmeoClarinetComponent } from './views/emeo-clarinet/emeo-clarinet.component';
import { EmeoSaxophoneComponentV1 } from './views/emeo-saxophone-v1/emeo-saxophone-v1.component';
import { EmeoSaxophoneComponentV2 } from './views/emeo-saxophone-v2/emeo-saxophone-v2.component';

import { EmeoDiagramClarinetV1Component } from './svg/emeo-clarinet-v1/emeo-diagram-clarinet-v1.component';
import { EmeoDiagramSaxophoneV1Component } from './svg/emeo-saxophone-v1/emeo-diagram-saxophone-v1.component';
import { EmeoDiagramSaxophoneV2Component } from './svg/emeo-saxophone-v2/emeo-diagram-saxophone-v2.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SerialSelectionComponent } from './views/electron/serial-selection/serial-selection.component';
import { MidiPlaybackComponent } from './views/midi-playback/midi-playback.component';
import { MidiPlaybackDiagramComponent } from './views/midi-playback-diagram/midi-playback-diagram.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MainComponent,
    InstrumentComponent,
    SettingsComponent,
    BackupComponent,
    UserDefinedKeysComponent,
    EmeoSaxophoneComponentV1,
    EmeoSaxophoneComponentV2,
    EmeoClarinetComponent,
    FlasherComponent,
    BackupRestoreComponent,
    BackupListComponent,
    DeviceStatusComponent,
    BackupInfoComponent,
    ConfirmationDialogComponent,
    RestoreComponent,
    VersionComponent,
    FooterComponent,
    ProgressComponent,
    DeviceSummaryComponent,
    NoteComponent,
    NoteConfigComponent,
    KeyPickerComponent,
    ChangeLogComponent,
    InstrumentConfigComponent,
    PlayasongComponent,
    ScoreComponent,
    NoteConfigComponent,
    NoteComponent,
    BackupListComponent,
    TapeViewComponent,
    TapeSliceComponent,
    TunerComponent,
    VolumeMeterComponent,
    VisualizerComponent,
    ScoreDialogComponent,
    TapeDialogComponent,
    ListenerControlComponent,
    DurationPipe,
    SupportRequestComponent,
    EmeoDiagramClarinetV1Component,
    EmeoDiagramSaxophoneV1Component,
    EmeoDiagramSaxophoneV2Component,
    ReportComponent,
    TuningMarkerComponent,
    PitchIndicatorComponent,
    PressureIndicatorComponent,
    DynamicComponentComponent,
    DynamicComponentDirective,
    FingeringModalComponent,
    InstrumentDiagramComponent,
    FeatureSelectorComponent,
    RecoveryModeComponent,
    SensorsComponent,
    SerialSelectionComponent,
    MidiPlaybackComponent,
    MidiPlaybackDiagramComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ScrollingModule,
  ],
  exports: [
    EmeoSaxophoneComponentV1,
    EmeoClarinetComponent,
  ],
  providers: [ 
    HardwareSupportGuard, 
    FirmwareService,
    FeatureSupportService
  ],
  bootstrap: [
    AppComponent
  ],

})
export class AppModule { }
