import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from '../../helpers/instrument-component-base';
import { HardwareTypes } from '../../model/device-info';
import { FirmwareInfo } from '../../model/firmware-info';
import { InstrumentConnection, ConnectionState } from '../../model/instrument-connection';
import { ConnectionService } from '../../services/connection.service';
import { Feature, FeatureSupportService } from '../../services/feature-support.service';
import { FirmwareService } from '../../services/firmware.service';
import { FlasherService } from '../flasher/flasher.service';

@Component({
  selector: 'app-recovery-mode',
  templateUrl: './recovery-mode.component.html',
  styleUrls: ['./recovery-mode.component.scss']
})
export class RecoveryModeComponent extends InstrumentComponentBase implements OnInit {

  @Input()
  updateFirmwares: FirmwareInfo[] = [];

  constructor(
    cs: ConnectionService,
    protected fs: FeatureSupportService,
    cd: ChangeDetectorRef,
    protected modalService: NgbModal,
    protected flasherService: FlasherService,
    protected firmwareService: FirmwareService) {

    super(cs);

    let control = new UntypedFormControl(false);

    this.consentControl = new UntypedFormGroup({
        consent: control
    });

    control.valueChanges.subscribe(val => {
      this.consent = val;
      cd.detectChanges();
    });
  }
  
  public consentControl: UntypedFormGroup;

  public consent: boolean = false;

  close() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {

  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.cs.emeoConnection }, ...features);
  }

  async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    if (!current) {
      this.updateFirmwares = [];
    }
  }

  async flashFirmware() {
    await this.flasherService.openFlasher(this.updateFirmwares);
  }

  async updateFirmware() {

    if (this.updateFirmwares.length == 0) {

      this.close()

      let firmwareVersions = await this.firmwareService.getFirmwareVersions(HardwareTypes.Sax) || [];

      let allowDowngrades = this.can(Feature.FreeFirmwareSelection);
      let onlyLatest = !this.can(Feature.FreeFirmwareSelection);
  
      this.updateFirmwares = this.firmwareService.findUpdates(
        HardwareTypes.Sax, 1, 
        "",
        firmwareVersions, 
        onlyLatest, allowDowngrades);

      if (this.updateFirmwares.length > 0) {
        this.flashFirmware();
      }
    }
    else {
      this.flashFirmware();
    }
}  
}
