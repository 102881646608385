import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {InstrumentComponentBase} from "../../../helpers/instrument-component-base";
import {ConnectionService} from "../../../services/connection.service";
import {ListenerService} from "../../../services/listener.service";
import {HttpClient} from "@angular/common/http";
import {RecordingsService} from "../../../services/recordings.service";
import {MetronomeService} from "../../../services/metronome.service";
import {ConnectionState, InstrumentConnection} from "../../../model/instrument-connection";
import {BreathEvent, KeyEvent, MetronomeEvent, NoteEvent, RecordingEvent} from "../../../model/recording-events";
import {Recording} from "../../../model/recording";
import {decodeKeys, UNCRecord, UNCSettings, UNCType} from "../../../model/uncsettings";
import {
  InstrumentViewComponentBaseComponent,
  UNCKeyInfo
} from "../../../helpers/instrument-view-component-base.component";
import {rootViews} from "../../../helpers/log-config";
import {getNoteByValue} from "../../../helpers/notes";
import {Feature, FeatureSupportService} from "../../../services/feature-support.service";
import {DeviceInfo, EMEO_SAX_V1, HardwareInfo, HardwareTypes} from "../../../model/device-info";
import {findDeviceInfo} from "../../../model/instruments/device-definitions";

const log = rootViews.getChildCategory("score");

type PlaybackReceiver = (e : RecordingEvent<any>) => void;

class RecordingPlayer {

  private _timerId : NodeJS.Timeout | null = null;

  readonly _recordingStart : number;

  constructor(protected _recording : Recording) {
    this._recordingStart = this._recording.firstTimestamp;
  }

  public async play(receiver : PlaybackReceiver) : Promise<void> {
    this.next(receiver, performance.now(), this._recording.events.entries());
  }

  protected next(
      receiver : PlaybackReceiver,
      startTime : number,
      iterator :  IterableIterator<[number, RecordingEvent<any>]>,
      current : RecordingEvent<any> | null = null) : void {

    if (current != null) {
      receiver(current.clone(performance.now()));
    }

    if (this._timerId != null) {
      clearTimeout(this._timerId);
    }

    while (true) {
      let entry = iterator.next();

      if (entry.done) {
        console.log("Done with playback");
        return;
      }

      // Get the event and look how far in the future it is
      let event = entry.value[1];
      let timeOffset = event.startTime - this._recordingStart;
      let currentOffset = performance.now() - startTime;

      let delta = timeOffset - currentOffset;

      if (delta > 0) {
        this._timerId = setTimeout(this.next.bind(this), delta, receiver, startTime, iterator, event);
        return;
      }
      else {
        receiver(event.clone(performance.now()));
      }
    }
  }
}

@Component({
  selector: 'app-recorder-view',
  templateUrl: './recorder-view.component.html',
  styleUrl: './recorder-view.component.scss'
})
export class RecorderViewComponent extends InstrumentComponentBase implements OnInit, AfterViewInit, OnDestroy {

  protected useMetronome = false;

  constructor(
      protected cs: ConnectionService,
      protected fs: FeatureSupportService,
      protected cd: ChangeDetectorRef,
      protected ls: ListenerService,
      protected http: HttpClient,
      protected rs: RecordingsService,
      protected ms: MetronomeService) {

    super(cs);

    // this.ls.on('pitch-detected', this.pitchDetected.bind(this));
    // this.ls.on('volume', this.volumeChange.bind(this));

    // this.ms.on('tick', (tick, denominator) => {
    //   // log.debug("Tick: " + tick + " of " + denominator);
    // });

    this.ms.on('beat', this.onMetronomeBeat.bind(this));

    this.prepareRecording(null).then( () => {
      log.debug("Ready");
    });
  }

  async ngOnInit() {

    if (this.currentInstrument) {
      await this.enableListeners(this.currentInstrument);
    }

  }

  async ngAfterViewInit() {
  }

  async ngOnDestroy(): Promise<void> {

    log.debug("recording ngOnDestroy");

    this.recording?.stopRecording();

    this.ms.off('beat', this.onMetronomeBeat.bind(this));

    if (this._watchdogId) {
      clearTimeout(this._watchdogId);
      this._watchdogId = undefined;
    }

    await super.ngOnDestroy();

    if (this.currentInstrument) {
      await this.disableListeners(this.currentInstrument);
    }
  }

  async enableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(this._trackUNCPresses, instrument);
  }

  async disableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(false, instrument);
  }

  private _trackUNCPresses: boolean = false;

  private _midiListener?: (data: Uint8Array, receivedTime: number | undefined) => void;

  private _uncListener?: (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => void;

  onMetronomeBeat(measure : number, beat : number) {
    // Do not record counting in...
    if (measure > 0) {
      this.recording?.addEvent(new MetronomeEvent(measure, beat));
    }
  }

  @ViewChild('instrumentView')
  instrumentView! : InstrumentViewComponentBaseComponent;

  get instrumentType() : HardwareTypes {
    return this._hardwareInfo?.type || HardwareTypes.Sax; // HardwareTypes.NONE;
  }

  private _deviceInfo?: DeviceInfo;

  private _hardwareInfo?: HardwareInfo;

  get hardwareInfo(): HardwareInfo | undefined {
    return this._hardwareInfo;
  }

  private set hardwareInfo(info: HardwareInfo | undefined) {
    this._hardwareInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  }

  public mouseOverEvents(events : RecordingEvent<any>[]) {

    let deviceInfo = findDeviceInfo(this.hardwareInfo || EMEO_SAX_V1);

    if (deviceInfo) {
      events
          .filter(event => event instanceof KeyEvent)
          .map( event => event as KeyEvent)
          .forEach( event => {
            this.instrumentView.pressed = decodeKeys(event.keys);
            this.instrumentView.uncType = event.uncType;
          });
    }
  }

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    this._uncTable = undefined;

    if (before) {
      await this.disableListeners(before);
    }

    if (current) {
      this._status = ConnectionState.LOADING;
      await this.loadDeviceInfo();

      await this.enableListeners(current);
    }
    else {
      this.hardwareInfo = undefined;
      this._deviceInfo = undefined;
    }
  }


  @Input()
  set trackUNCPresses(flag: boolean) {

    if (this._trackUNCPresses != flag) {
      this._trackUNCPresses = flag;
      if (this.currentInstrument) {
        this.setupUNCPresses(flag, this.currentInstrument);
      }
    }
  }

  get trackUNCPresses(): boolean {
    return this._trackUNCPresses;
  }

  private async setupUNCPresses(flag: boolean, instrument: InstrumentConnection) {

    if (!flag) {
      await instrument.stopUNCMessages();

      if (this._midiListener) {
        instrument.off('midi', this._midiListener);
        this._midiListener = undefined;
      }

      if (this._uncListener) {
        instrument.off('unc', this._uncListener);
        this._uncListener = undefined;
      }
    }
    else {
      this._uncListener = (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => {

        if (data.keyState) {
          this.instrumentView.pressed = decodeKeys(data.keyState);;
          this.recording?.addEvent(new KeyEvent(data.keyState, data.type));
        }
      }

      this._midiListener = (data: Uint8Array, receivedTime: number | undefined) => {

        // log.debug("Received midi message");

        let cmd = ((data[0] & 0xf0) >> 4);
        let channel = ((data[0] & 0x0f) + 1);

        switch (cmd) {
          // NOTE OFF
          case 0x8: {
            let velocity = data[2];
            this.recording?.addEvent(new NoteEvent(data[1], false));
            break;
          }

          // NOTE ON
          case 0x9: {
            let note = getNoteByValue(data[1] % 12) + Math.floor(data[1] / 12);
            let velocity = data[2];
            this.recording?.addEvent(new NoteEvent(data[1], true));
            break;
          }

          case 0xb: {
            if (data[1] == 0x2) {
              this.recording?.addEvent(new BreathEvent(data[2]));
            }
            break;
          }

          case 0xd: {
            this.recording?.addEvent(new BreathEvent(data[1]));
            break;
          }
        }
      }

      this._recording = this._recording ?? new Recording();

      instrument.on("midi", this._midiListener);
      instrument.on("unc", this._uncListener);

      await instrument.startUNCMessages();
    }
  }

  private _recording: Recording | undefined = undefined;

  get recording(): Recording | undefined {
    return this._recording;
  }

  get isRecording() : boolean {
    return this._recording?.isRecording || false;
  }

  async togglePlay() {
    if (this.isRecording) {
      await this.stopMetronome();
    }
    else {
      await this.prepareRecording(null);
    }
  }

  async stopMetronome() {

    this.trackUNCPresses = false;

    this.ms.stop();

    this._recording?.stopRecording();

    log.debug(this.recording?.toJSON() || '{}');

    if (this._recording) {
      await this.rs.addLocalRecording(this._recording);
    }

    this._recording = undefined;
  }

  silenceBeforePause : number = 2000;

  protected _watchdogId? : any = undefined;

  protected resetPauseWatchdog(recording : Recording, event: RecordingEvent<any>[]) {

    if (event.some( e => !(e instanceof MetronomeEvent))) {

      if (this._watchdogId) {
        clearTimeout(this._watchdogId);
        this._watchdogId = undefined;
      }

    }

    if (!this._watchdogId) {
      this._watchdogId = setTimeout(this.onPauseRecording.bind(this), this.silenceBeforePause);
    }

  }

  private onPauseRecording() {
    log.debug("Triggered pause");

    if (this._watchdogId) {
      clearTimeout(this._watchdogId);
      this._watchdogId = undefined;
    }

    this.ms.stop();
    this.recording?.pause(this.silenceBeforePause);
  }

  async prepareRecording(recording : Recording | null) {

    this._recording = recording == null ? new Recording() : recording;

    this._recording.addListener('eventsAdded', this.resetPauseWatchdog.bind(this));

    this.trackUNCPresses = true;

    if (this.useMetronome) {
      let ticks = 1000; // this.scoreView.ticks || 0;
      let bpm = 80; // this.scoreView.bpm || 80;
      let rhythmDenominator = 1; // this.scoreView.rhythmDenominator || 4;
      let rhythmNumerator = 1; // this.scoreView.rhythmNumerator || 4;

      ticks = bpm;

      this.ms.prepareMetronome(rhythmDenominator, rhythmNumerator, bpm, ticks);
      log.debug("Ticks per minute: " + this.ms.ticksPerMinute);
    }

    this._recording?.startRecording(true);

    if (this.useMetronome) {
      this.ms.start(true);
    }
  }

  get currentBeat(): number {
    return this.ms.beat;
  }

  get currentMeasure(): number {
    return this.ms.measure;
  }

  private _bpm: number = 80;

  get bpm(): number {
    return this._bpm;
  }

  protected _rhythmDenominator: number = 4;

  get rhythmDenominator() : number {
    return this._rhythmDenominator;
  }

  protected _rhythmNumerator: number = 4;

  get rhythmNumerator() : number {
    return this._rhythmNumerator;
  }


  // pitchDetected(pitch: PitchMessage | undefined) {
  //
  //   if (pitch && pitch.index) {
  //     let octave = Math.floor((pitch.index - 11) / 12);
  //     let note = this.smoothNote(noteStrings[pitch.index % 12]);
  //
  //     log.debug("Detected: " + octave + " // " + note + " from " + JSON.stringify(pitch));
  //
  //     if (octave && note)
  //       if ((this.activeNote?.note != note) || (this.activeNote?.octave != octave)) {
  //         this.flushVoicePitch();
  //
  //         this.activeNote = {
  //           since: performance.now(),
  //           note: note,
  //           octave: octave,
  //           midi: pitch.index
  //         }
  //       }
  //   }
  //   else {
  //     this.flushVoicePitch();
  //   }
  // }
  //
  // silenceThreshold: number = 10;
  //
  // volume: number = 0;
  //
  // volumeChange(volume: number) {
  //   if (this.volume < this.silenceThreshold) {
  //     //this.flushVoicePitch();
  //   }
  //
  //   this.volume = volume;
  // }
  //
  // protected flushVoicePitch() {
  //   if (this.activeNote) {
  //     let duration = performance.now() - this.activeNote.since;
  //
  //     if (duration > 20) {
  //       log.debug("Flush: " + JSON.stringify(this.activeNote));
  //       this._recording?.addEvent(new ListenedNoteEvent(this.activeNote.midi, this.activeNote.since, duration));
  //     }
  //   }
  //
  //   this.activeNote = undefined;
  // }
  //
  // protected smoothingValue: 'none' | 'basic' | 'very' = 'very';
  //
  // protected smoothingCount: number = 0;
  // protected lastNote: string = "";
  // protected currentNote: string = "";
  //
  // protected smoothNote(newNote: string) : string {
  //
  //   let smoothingCountThreshold = 0;
  //
  //   if (this.smoothingValue === 'none') {
  //     smoothingCountThreshold = 0;
  //   }
  //   else if (this.smoothingValue === 'basic') {
  //     smoothingCountThreshold = 5;
  //   }
  //   else if (this.smoothingValue === 'very') {
  //     smoothingCountThreshold = 10;
  //   }
  //
  //   // Check if this value has been within the given range for n iterations
  //   if (newNote === this.lastNote) {
  //     if (this.smoothingCount < smoothingCountThreshold) {
  //       this.smoothingCount++;
  //     }
  //     else {
  //       this.currentNote = newNote;
  //       this.smoothingCount = 0;
  //     }
  //   }
  //   else {
  //     this.lastNote = newNote;
  //     this.smoothingCount = 0;
  //   }
  //
  //   return this.currentNote;
  // }
  //
  // protected processCurrentNote(notes: Note[] | undefined, ticks: number) {
  //
  //   if (notes && notes.length > 0) {
  //     let note = notes[0];
  //     log.debug("Note-Measure: " + note.SourceMeasure?.MeasureNumber + " // Metronome-Measure: " + this.currentMeasure);
  //
  //     if (note.Length.Numerator != 0) {
  //       this._noteCount = (ticks / note.Length.Denominator) * note.Length.Numerator;
  //     }
  //     else {
  //       this._noteCount = ticks;
  //     }
  //
  //     if (!note.isRest()) {
  //       let ht = note.Pitch.getHalfTone();
  //       log.debug("Note: " + note.Pitch.ToString() + " // " + ht);
  //       let duration = 60000 / this.ms.ticksPerMinute * this._noteCount;
  //
  //       this._recording?.addEvent(new ScoreEvent(note.Pitch.getHalfTone(), duration))
  //     }
  //
  //     log.debug("New note count " + this._noteCount);
  //   }
  // }

  async toggleMetronome() {

    if (this.ms.isRunning) {
      this.ms.stop();
    }
    else {
      this.ms.prepareMetronome(1, 1, 70, 1);
      this.ms.start(false);
    }
  }

  /** The scale for the depiction of the recording */
  private _scale: number = 1.0;

  @Input()
  get scale(): number {
    return this._scale;
  }

  set scale(scale: number) {

    if (this._scale != scale) {
      this._scale = scale;
    }
  }

  zoomReset() {
    this._scale = 1;
  }

  zoomIn() {
    this.scale = this.scale * 1.5;
  }

  zoomOut() {
    this.scale = this.scale * 0.5;
  }

  toggleAudio() {
    this.ms.toggleAudio();
  }

  toggleRecording() {
    if (this._recording?.isPaused) {
      this._recording?.continue();
    }
    else {
      this._recording?.pause();
    }
  }

  can(...features: string[]) : boolean {
    return this.fs.can(undefined, ...features);
  }


  async loadDeviceInfo() : Promise<void> {

    if (this.currentInstrument) {
      if (this.currentInstrument.state === 'ready') {
        await this.fetchDeviceInfo(this.currentInstrument);
      }
      else {
        this.currentInstrument.on('statechange', (connection: InstrumentConnection, state: ConnectionState) => {
          if (state === ConnectionState.READY) {
            this.fetchDeviceInfo(connection).then( () => {
              this._status = state;
            });
          }
          else {
            this._status = state;
          }
        });
      }
    }
    else {
      this.deviceInfo = undefined;
    }
  }

  private _uncTable: UNCSettings | undefined;

  private async fetchDeviceInfo(connection: InstrumentConnection) : Promise<void> {
    this.deviceInfo = connection.deviceInfo;
    this.hardwareInfo = connection.deviceInfo.hardware;

    if (this.instrumentView) {
      if (this.can(Feature.UNCEditor) && this._uncTable === undefined ) {
        log.debug("Loading UNC-Table from instrument")
        this._uncTable = await connection.loadUNCTable()
      }
    }
  }

  private _status : ConnectionState = ConnectionState.READY;

  public get status() : ConnectionState {
    return this._status;
  }

  get deviceInfo(): DeviceInfo | undefined {
    return this._deviceInfo;
  }

  private set deviceInfo(info: DeviceInfo | undefined) {
    this._deviceInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  }

}
