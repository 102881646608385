import { ChargeState, InstrumentState } from '../../model/instrument-state';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
import { InstrumentStatusComponentBase } from 'src/app/helpers/instrument-status-component-base';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { Observable, Subscription, timer } from 'rxjs';
import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { rootService } from 'src/app/helpers/log-config';
import { sleep } from 'bossa-web';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

const log = rootService.getChildCategory("connection");

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss']
})
export class DeviceStatusComponent extends InstrumentStatusComponentBase implements OnDestroy {
  
  readonly midiListener: (data: Uint8Array, receivedTime: number | undefined) => void;
  
  constructor(
    public fs: FeatureSupportService,
    protected cs: ConnectionService,
    protected cds: ConfirmationDialogService,
    protected cd: ChangeDetectorRef) { 
      
    super(cs);
      
    this.midiListener = this.onMidiMessage.bind(this);
  }

  uncEnabled: boolean = false;
  glitchReducerEnabled: boolean = false;
  glitchLearnEnabled: boolean = false;
  overblowEnabled: boolean = false;
  playWithoutBlowingEnabled: boolean = false;
  outputKSRSysexEnabled: boolean = false;
  outputKeyStrokeSysexEnabled: boolean = false;
  outputConditionalKeyStrokeSysexEnabled: boolean = false;
  uncBank: number = 0x00;
  chargeState: ChargeState = ChargeState.NO_STATE;
  advancedMode: boolean = false;

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    await super.ngOnDestroy();

    this.connectMidiListeners(undefined, this.currentInstrument);
  }
  
  async onMidiMessage(data: Uint8Array, receivedTime: number | undefined) {

    if (this.currentInstrument) {
      // Ignore the device state messages
      if (!this.currentInstrument.isValidHeader(data, [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x13, 0x62, 0x00, 0x00 ])) {
        this.setupWatchdogs();
      }
    }

  }

  private refreshTimer?: Observable<any> = undefined;
  private refreshSubscription?: Subscription = undefined;

  private sleepTimer?: Observable<any> = undefined;
  private sleepSubscription?: Subscription = undefined;

  private setupWatchdogs() {
    if (!this.refreshTimer) {
      log.debug("Creating new refreshTimer");
      this.refreshTimer = timer(10000, 10000);
      this.refreshSubscription = this.refreshTimer.subscribe(val => this.refreshState());  
    } 

    this.stopSleepWatchdog();

    this.sleepTimer = timer(60000);
    this.sleepSubscription = this.sleepTimer.subscribe(val => this.stopWatchdog());
  }

  private stopWatchdog() {
    log.debug("Stopping watchdogs");

    this.refreshSubscription?.unsubscribe();

    this.refreshSubscription = undefined;
    if (this.refreshTimer) {
      log.debug("Deleting refreshTimer");
      this.refreshTimer = undefined;
      this.refreshSubscription = undefined;
    }

    this.stopSleepWatchdog();
  }

  private stopSleepWatchdog() {

    this.sleepSubscription?.unsubscribe();

    if (this.sleepTimer) {
      this.sleepTimer = undefined;
      this.sleepSubscription = undefined;
    }
  }

  async refreshState() {
    if (this.currentInstrument) {
      if (!this.currentInstrument.blockRefresh) {
        log.debug("Refresh state");
        this.currentInstrument.fetchDeviceState();
      }
    }
  }

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    await super.onInstrumentChanged(current, before);

    this.connectMidiListeners(current, before);
  }


  private connectMidiListeners(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) {
        
    if (this.midiListener) {

        if (before) {
            before.removeListener('midi-message', this.midiListener);
        }

        if (current) {
            current.addListener('midi-message', this.midiListener);
        }
    }

    this.hasStatus = false;
}

  override async onStateUpdated(state: InstrumentState) {

    this.uncEnabled = state.uncEnabled;
    this.glitchReducerEnabled = state.glitchReducerEnabled;
    this.glitchLearnEnabled = state.glitchLearnEnabled;
    this.overblowEnabled = state.overblowEnabled;
    this.playWithoutBlowingEnabled = state.playWithoutBlowingEnabled;
    this.outputKSRSysexEnabled = state.outputKSRSysexEnabled;
    this.outputKeyStrokeSysexEnabled = state.outputKeyStrokeSysexEnabled;
    this.outputConditionalKeyStrokeSysexEnabled = state.outputConditionalKeyStrokeSysexEnabled;
    this.uncBank = state.uncBank;
    this.chargeState = state.chargeState; 
    this.advancedMode = state.advancedMode;
    
    this.cd.detectChanges();
  }

  async disableAdvancedMode() {

    let disable = await this.cds.confirm("Advanced Mode", "Do you want to disable the advanced mode?", "Yes", "No", "sm");

    if (disable) {
      await this.currentInstrument?.disableAdvancedMode();
      await this.refreshState()
    }
  }

  togglePlayWithoutBlowing() {
    if (this.currentInstrument) {
      this.playWithoutBlowingEnabled = !this.playWithoutBlowingEnabled;
      this.currentInstrument.setupPlayWithoutBlow(this.playWithoutBlowingEnabled);
      this.cd.detectChanges();
    }
  }

  toggleGlitchReducer() {
    if (this.currentInstrument) {
      this.glitchReducerEnabled = !this.glitchReducerEnabled;
      this.currentInstrument.setupGlitchReducer(this.glitchReducerEnabled);

      if (!this.glitchReducerEnabled) {
        this.glitchLearnEnabled = false;
      }

      this.cd.detectChanges();
    }
  }

  toggleGlitchLearn() {
    if (this.currentInstrument) {
      this.glitchLearnEnabled = !this.glitchLearnEnabled;
      this.currentInstrument.setupGlitchLearn(this.glitchLearnEnabled);
      this.cd.detectChanges();
    }
  }

  toggleUserNotesMode() {
    if (this.currentInstrument) {
      this.uncEnabled = !this.uncEnabled;
      this.currentInstrument.setupUNCMode(this.uncEnabled);
      this.cd.detectChanges();
    }
  }

  toggleOverblow() {
    if (this.currentInstrument) {
      this.overblowEnabled = !this.overblowEnabled;
      this.currentInstrument.setupOverblowMode(this.overblowEnabled);
      this.cd.detectChanges();
    }    
  }  

  enterDeepSleep() {
    if (this.currentInstrument) {
      this.currentInstrument.rebootWithHibernate().then( () => {
        sleep(100);
        this.cs.disconnect();
      });
    }    
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.currentInstrument }, ...features);
  }
}
