import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureSupportService } from '../../services/feature-support.service';
import { VERSION } from '../../../environments/version';
import { ChangeLogComponent } from '../change-log/change-log.component';
import { MidiPlaybackComponent } from '../midi-playback/midi-playback.component';
import { RecoveryModeComponent } from '../recovery-mode/recovery-mode.component';
import { SupportRequestComponent } from '../support-request/support-request.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    version = VERSION;

    constructor(
      public fs: FeatureSupportService,
      protected modalService: NgbModal) {
    }

    showChangelog() {
      this.modalService.dismissAll();

      this.modalService.open(ChangeLogComponent, { backdrop: 'static', scrollable: true, centered: true, size: 'lg', container: "#fullscreenContainer"  });
    }

    enterRecoveryTool() {
      this.modalService.dismissAll();

      this.modalService.open(RecoveryModeComponent, { backdrop: 'static', scrollable: true, centered: true, size: 'lg', container: "#fullscreenContainer"  });
    }

    showMidiPlaybackView() {
      this.modalService.dismissAll();
      this.modalService.open(MidiPlaybackComponent, { backdrop: 'static', scrollable: true, centered: true, size: 'lg', container: "#fullscreenContainer"  });
    }

    createSupportRequest() {
      this.modalService.dismissAll();

      this.modalService.open(SupportRequestComponent, { backdrop: 'static', scrollable: true, centered: true, size: 'lg', container: "#fullscreenContainer"  });
    }

    can(...features: string[]) : boolean {
      return this.fs.can({  }, ...features);
    }
}