import { Injectable } from '@angular/core';
import { rootService } from '../helpers/log-config';
import { environment as CONFIG } from '../../environments/environment';
import { INSTRUMENT_TYPES, InstrumentConnectionInfo, InstrumentType } from '../model/device-info';
import { compareVersions } from '../helpers/version-helpers';

const log = rootService.getChildCategory("featureSupport");

export enum Feature {

  BackupRestore = 'BackupRestore',
  InvalidNoteKey = 'InvalidNoteKey',
  GlobalSettings = 'GlobalSettings',
  Detection = 'Detection',
  UserNoteConfig = 'UserNoteConfig',
  ConditionalUNCMessages = 'ConditionalUNCMessages',
  VeryHardResistance = 'VeryHardResistance',
  UNCEditor = 'UNCEditor',
  FreeFirmwareSelection = 'FreeFirmwareSelection',
  BLEDirectConnection = 'BLEDirectConnection',
  MIDIConnection = 'MIDIConnection',
  AdditionalKeys = 'AdditionalKeys',
  KeyTypeColoring = 'KeyTypeColoring',
  NinaFlasher = 'NinaFlasher',
  RebootDeepSleep = 'RebootDeepSleep',
  UNCBanks = 'UNCBanks',
  ExtendedPowerInfo = 'ExtendedPowerInfo',
  LearnRehersal = 'LearnRehersal',
  BreathInfo = 'BreathInfo',
  Not202 = 'Not202',
  Updater = 'Updater',
  PDFExport = 'PDFExport',
  AdvancedFeatureSwitch = 'AdvancedFeatureSwitch',
  SleepModeOff = 'SleepModeOff',
  Clarinet = 'Clarinet',
  FingeringZoom = 'FingeringZoom',
  SensorDisplay = 'SensorDisplay',
  Builds = 'Builds',
  BM20Firmwaremode = 'BM20Firmwaremode',
  MIDIPlaybackView = 'MIDIPlaybackView',
}

interface FeatureSpec {

  supported(context: FeatureContext) : boolean;
}

interface FeatureContext {
  connection?: InstrumentConnectionInfo;
}

interface InternalFeatureContext extends FeatureContext {
  maintenanceMode: boolean;
  developmentMode: boolean;

  features: { [ key: string ] : boolean };
}

abstract class AbstractFeatureSpec implements FeatureSpec {

  supported(context: InternalFeatureContext): boolean {
    throw new Error('Method not implemented.');
  }
}

class BooleanFeature extends AbstractFeatureSpec {

  constructor(private _result : boolean) {
    super();
  }

  supported(context: InternalFeatureContext): boolean {
    return this._result;
  }
}

class FirmwareVersionFeature extends AbstractFeatureSpec {

  private _fromVersion: string;

  private _toVersion?: string;

  constructor(fromVersion: string, toVersion: string | undefined = undefined) {
    super();

    this._fromVersion = fromVersion;
    this._toVersion = toVersion;
  }

  supported(context: InternalFeatureContext) : boolean {

    let result = false;

    if (context.connection) {
      let info = context.connection.deviceInfo
      let version = info.firmwareVersion

      result = (compareVersions(version, this._fromVersion) <= 0);

      if (this._toVersion) {
        result &&= (compareVersions(version, this._toVersion) >= 0);
      }
    }

    return result;
  }
}

class ElectronFeature extends AbstractFeatureSpec {
  constructor() {
    super()
  }

  supported(context: InternalFeatureContext): boolean {
    return window.isElectron || false;
  }
}

class DevelopmentModeFeature extends AbstractFeatureSpec {

  constructor() {
    super();
  }

  supported(context: InternalFeatureContext) : boolean {
    return context.developmentMode;
  }
}

class MaintenanceModeFeature extends AbstractFeatureSpec {

  constructor() {
    super();
  }

  supported(context: InternalFeatureContext) : boolean {
    return context.maintenanceMode;
  }
}

class IsEnabledFeature extends AbstractFeatureSpec {

  constructor(private name:string) {
    super();
  }

  supported(context: InternalFeatureContext) : boolean {
    return (context.features[ this.name ] === true);
  }
}
class NotFeatureSpec extends AbstractFeatureSpec {

  constructor(private spec: FeatureSpec) {
    super();
  }

  supported(context: InternalFeatureContext) : boolean {
    return !this.spec.supported(context);
  }
}

class AnyFeaturesSpec extends AbstractFeatureSpec {

  private _specs: FeatureSpec[];

  constructor(...specs: FeatureSpec[]) {
    super();

    this._specs = specs;
  }

  supported(context: InternalFeatureContext) : boolean {
    return this._specs.reduce<boolean>( (prev, curr) => ( curr.supported(context) || prev ), false);
  }
}

class AllFeaturesSpec extends AbstractFeatureSpec {

  private _specs: FeatureSpec[];

  constructor(...specs: FeatureSpec[]) {
    super();

    this._specs = specs;
  }

  supported(context: InternalFeatureContext) : boolean {
    return this._specs.reduce<boolean>( (prev, curr) => ( curr.supported(context) && prev ), this._specs.length > 0);
  }
}

function not(spec: FeatureSpec) : FeatureSpec {
  return new NotFeatureSpec(spec);
}

function any(...specs: FeatureSpec[]) : FeatureSpec {
  return new AnyFeaturesSpec(...specs);
}

function all(...specs: FeatureSpec[]) : FeatureSpec {
  return new AllFeaturesSpec(...specs);
}

function firmware(fromVersion: string, toVersion: string | undefined = undefined) : FeatureSpec {
  return new FirmwareVersionFeature(fromVersion, toVersion);
}

function disabled() : FeatureSpec {
  return new BooleanFeature(false);
}

function enabled() : FeatureSpec {
  return new BooleanFeature(true);
}

function developmentMode() : FeatureSpec {
  return new DevelopmentModeFeature();
}

function electron() : FeatureSpec {
  return new ElectronFeature();
}

function maintenanceMode() : FeatureSpec {
  return new MaintenanceModeFeature();
}

type FeatureMap = {
  [feature in string]: FeatureSpec;
}

function updater() : FeatureSpec {
  return ( CONFIG.updater ? enabled() : disabled() );
}

function feature(name: string) : FeatureSpec {
  return new IsEnabledFeature(name);
}

const FEATURES: FeatureMap = {
  'Updater': updater(),
  'Not202': all( not(updater()), firmware('4')),
  'BackupRestore': all( not(updater()), firmware('4')),
  'InvalidNoteKey': firmware('4.2j'),
  'GlobalSettings': all( not(updater()), firmware('4')),
  'Detection': all( not(updater()), firmware('4'), feature('detection')),
  'UserNoteConfig' : all( firmware('4'), feature('unc')),
  'VeryHardResistance': disabled(),
  'ConditionalUNCMessages': firmware('4.2o'),
  'UNCEditor': feature('unc'),
  'BLEDirectConnection': disabled(),
  'FreeFirmwareSelection': any( maintenanceMode(), developmentMode()),
  'AdditionalKeys': firmware('4.3'),
  'MIDIConnection': disabled(),
  'KeyTypeColoring': firmware('4.3'),
  'NinaFlasher': firmware('4.3.0.5'),
  'RebootDeepSleep': firmware('4.3.0.7'),
  'UNCBanks': firmware('4.3'),
  'ExtendedPowerInfo': firmware('4.3.9.8'),
  'LearnRehersal': feature('learn'),
  'BreathInfo': firmware('4.3.10.9'),
  'PDFExport': enabled(),
  'AdvancedFeatureSwitch': all(firmware('4.3.20'), feature('advancedmode')),
  'SleepModeOff': all( firmware('4.3.17.17'), maintenanceMode()),
  'Clarinet': feature('clarinet'),
  'FingeringZoom': feature('fingeringzoom'),
  'SensorConfig': feature('sensors'),
  'Builds': feature('builds'),
  'BM20Firmwaremode': feature('bm20firmware'),
  'MIDIPlaybackView': feature('midiplaybackview'),
}

export type FeatureOptions = { [key:string]:string };

@Injectable({
  providedIn: 'root'
})
export class FeatureSupportService {

  constructor() { 
  }

  private _features: any = {};

  get features() : string[] {
    return Object.keys(this._features);
  }

  private _supportedFeatures: FeatureOptions = {
    'clarinet':         "Support for EMEO Clarinet.",
    'learn':            "Learning mode with recording and more.",
    'unc':              "User Defined key definitions.",
    'detection':        "Provide advanced settings for detection.",
    'advancedmode':     "Support enabling and disabling advanced mode.",
    'fingeringzoom':    "Provide a zoom view for the instrument.",
    'sensors':          "Control sensitivity of the sensors",
    'sounds':           "Manage on-board sounds",
    'builds':           "Support firmware development builds",
    'bm20firmware':     "BM20 firmware mode",
    'midiplaybackview': "MIDI Playback View",
  }

  supportedFeatures() : FeatureOptions {
    return { ...this._supportedFeatures };
  }

  enableFeature(feature: string) {
    this._features[feature.toLowerCase()] = true;
  }

  hasFeature(feature: string) : boolean {
    return (this._features[feature] === true);
  }

  private _welcomeShown: boolean = false;

  set welcomeShown(flag: boolean) {
    this._welcomeShown = flag;
  }

  get welcomeShown(): boolean {
    return this._welcomeShown;
  }
      
  get isSafari(): boolean {
    return navigator.appVersion.indexOf('Safari') > -1
  }

  get isMac(): boolean {
    return navigator.appVersion.indexOf('Mac') > -1
  }
  
  get isWindows(): boolean {
    if (this.developmentMode) {
      return true;
    }
    
    return navigator.appVersion.indexOf('Win') > -1
  }

  private _betaMode: boolean = false;

  set betaMode(flag: boolean) {
    this._betaMode = flag;
  }

  get betaMode(): boolean {
    return this._betaMode;
  }

  private _alphaMode: boolean = false;

  set alphaMode(flag: boolean) {
    this._alphaMode = flag;
  }

  get alphaMode(): boolean {
    return this._alphaMode;
  }

  private _developmentMode: boolean = false;

  set developmentMode(flag: boolean) {
    this._developmentMode = flag;
  }

  get developmentMode(): boolean {
    return this._developmentMode;
  }

  private _maintenanceMode: boolean = false;

  set maintenanceMode(flag: boolean) {
    this._maintenanceMode = flag;
  }

  get electronMode() : boolean {
    return window.isElectron || false;
  }
  
  get maintenanceMode(): boolean {
    return this._maintenanceMode;
  }

  can(context: FeatureContext | undefined , ...features: string[]) : boolean {

    context = this.prepareContext(context);

    let result: boolean = (features.length > 0);
    
    for(var feature of features) {

      let spec = FEATURES[feature];

      if (spec) {
        result &&= spec.supported(context);
      }
      else {
        log.warn("Invalid feature name " + feature);
      }
    }

    return result;
  }

  private prepareContext(context: FeatureContext | undefined) : InternalFeatureContext {
    return Object.assign(
      { 
        maintenanceMode: this._maintenanceMode,
        developmentMode: this._developmentMode,

        features: Object.assign({}, this._features),
      }, context);
  }

  protected _defaultBaudrate = 921600;

  public get baudrate() : number {
    return this._defaultBaudrate;
  }  

  protected _firmwareString : string | undefined;

  forceFirmware(firmware: string) {
    this._firmwareString = firmware;
  }

  public get overridingFirmware() : string | undefined {
    return this._firmwareString;
  }

  protected _instrumentType : InstrumentType | undefined;

  forceInstrumentType(hardware: string) {
    var found = INSTRUMENT_TYPES.filter( info => { return (info.id === hardware); } );

    if (found && found.length > 0) {
      this._instrumentType = found[ 0 ];
    }
  }

  public get overidingInstrumentType() : InstrumentType | undefined {
    return this._instrumentType;
  }
}
