import { EMEO_CLARINET_V1 } from "../device-info";
import { FingeringInfo, InstrumentInfo, KeyMap } from 'src/app/model/unc-types';

const DefaultFingerings_Clarinet_V1: FingeringInfo = {
  "XX0" : 44,                       
  "B:C:D:E:F:LowC:PinkyBb": 45, // A#, Bb
  "B:C:D:E:F:G:LowC:PinkyBb": 46,
  "B:C:D:E:F:G:LowC:PinkyB": 47,
  "B:C:D:E:F:G:LowC": 48,
  "B:C:D:E:F:G:LowC:PinkyCSharp": 49,
  "B:C:D:E:F:G": 50,
  "B:C:D:DSharp:E:F:G": 51,
  "B:C:E:F:G": 52,
  "B:C:F:G": 53,
  "B:C:E:G": 54,
  "B:C:G": 55,
  "B:C:E:F:G:PinkyGSharp": 56,
  "B:C": 57,
  "B:BFlat": 58,
  "B:C:PalmBFlat": 58,
  "B": 59,
  "C": 60,
  "": 61,
  "B:C:D:E:F:G:Octave": 62,
  "B:C:D:DSharp:E:F:G:Octave": 63,
  "B:C:E:F:G:Octave": 64,
  "B:C:F:G:Octave": 65,
  "B:C:E:G:Octave": 66,
  "B:C:G:Octave": 67,
  "B:C:G:Octave:PinkyGSharp": 68,
  "B:C:Octave": 69,
  "B:BFlat:Octave": 70,
  "B:C:PalmBFlat:Octave": 70,  
  "B:Octave": 71,
  "C:Octave": 72,
  "Octave": 73,
  "HighD:Octave": 74,
  "HighEFlat:Octave": 75,
  "HighF:Octave": 76,
  "HighD:HighE:HighEFlat:HighF:Octave": 77,
  "HighD:HighE:HighEFlat:HighF:AddtlFSharp:Octave": 78,                      // fis’’’/ges’’’
  "B:D:F:G:Octave:PalmBFlat": 79,
  "B:D:F:G:Octave:PalmC": 80,
  "B:C:G:Octave:PalmC": 81,
  "C:G:HighD:Octave": 82,
  "PalmC:Octave:G": 83, // ais'''
}

const KeyMap_Clarinet_V1: KeyMap = {
  0: { name: 'Octave', active: true  },
  1: { name: 'SideTrill2', active: true },
  2: { name: 'SideTrill1', active: true },
  3: { name: 'A', active: true, connectedKeys: [ "GSharp" ] },
  4: { name: 'E', active: false },
  5: { name: 'SideFSharp', active: true },
  6: { name: 'F', active: false },
  7: { name: 'GSharp', active: true },
  8: { name: 'D', active: false },
  9: { name: 'SideDSharp', active: true },
  10: { name: 'G', active: false },
  11: { name: 'CSharp', active: true },
  12: { name: 'B', active: false },
  13: { name: 'LowB', active: true },
  14: { name: 'Bb', active: false },
  15: { name: 'C', active: false, connectedKeys: [ "D" ] },
  16: { name: 'PinkyGSharp', active: true, sameKeys: [ "LowGSharp" ] },
  17: { name: 'PinkyE', active: false, connectedKeys: [ "PinkyF"], sameKeys: [ "LowE" ] },
  18: { name: 'DSharp', active: true },

  20: { name: 'PinkyFSharp', active: true, connectedKeys: [ "PinkyF"], sameKeys: [ "LowFSharp" ] },
  21: { name: 'PinkyF', active: false, sameKeys: [ "LowF" ] },

//  
//  15: { name: 'PinkyFSharp', active: true },
}

export const EMEO_CLARINET_V1_INFO : InstrumentInfo = {

  name: "EMEO Practice Clarinet",

  hardware: EMEO_CLARINET_V1,
  
  keyMap: KeyMap_Clarinet_V1,

  defaultFingerings: DefaultFingerings_Clarinet_V1,

  devices: [ EMEO_CLARINET_V1 ],
}