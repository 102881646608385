import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { HardwareInfo, HardwareTypes } from 'src/app/model/device-info';
import { ConnectionService } from 'src/app/services/connection.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { InstrumentViewComponentBaseComponent } from '../../helpers/instrument-view-component-base.component';

@Component({
  selector: 'app-fingering-modal',
  templateUrl: './fingering-modal.component.html',
  styleUrls: ['./fingering-modal.component.scss']
})
export class FingeringModalComponent extends InstrumentComponentBase implements OnInit {

  @ViewChild('instrumentView')
  instrumentView!: InstrumentViewComponentBaseComponent;

  constructor(
    public fs: FeatureSupportService,
    protected cs: ConnectionService,
    private modalService: NgbModal) { 

    super(cs);
  }
    
  ngOnInit(): void {
  }

  get instrumentType() : HardwareTypes {
    return this._hardwareInfo?.type || HardwareTypes.NONE;
  }
  
  private _hardwareInfo?: HardwareInfo;

  get hardwareInfo(): HardwareInfo | undefined {
    return this._hardwareInfo;
  }

  set hardwareInfo(info: HardwareInfo | undefined) {
    this._hardwareInfo = info;
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.cs.emeoConnection }, ...features);
  }

  closeZoomModal() {
    this.modalService.dismissAll();
  }
}
