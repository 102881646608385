import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { rootViews } from 'src/app/helpers/log-config';
import { PitchMessage } from 'src/app/helpers/pitch-node';
import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { BreathEvent, KeyEvent, ListenedNoteEvent, MetronomeEvent, NoteBaseEvent, NoteEvent, ScoreEvent } from 'src/app/model/recording-events';
import { Recording } from 'src/app/model/recording';
import { UNCRecord, UNCType, decodeKeys } from 'src/app/model/uncsettings';
import { ConnectionService } from 'src/app/services/connection.service';
import { MetronomeService } from 'src/app/services/metronome.service';
import { ScoreComponent, ScoreLoadedInfo } from '../score/score.component';
import { HttpClient } from '@angular/common/http';
import { ListenerService } from 'src/app/services/listener.service';
import { TapeDialogComponent } from '../tape-dialog/tape-dialog.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Score } from 'src/app/model/score';
import { RecordingsService } from 'src/app/services/recordings.service';
import { Note } from 'opensheetmusicdisplay';
import { UNCKeyInfo } from 'src/app/helpers/instrument-view-component-base.component';
import {getNoteByValue, NoteEntry, noteStrings} from "../../../helpers/notes";

const log = rootViews.getChildCategory("score");

@Component({
  selector: 'app-score-dialog',
  templateUrl: './score-dialog.component.html',
  styleUrls: ['./score-dialog.component.scss']
})
export class ScoreDialogComponent extends InstrumentComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    protected cs: ConnectionService,
    protected ls: ListenerService,
    protected http: HttpClient,
    protected ds: NgbModal,
    protected rs: RecordingsService,
    protected ms: MetronomeService) {

    super(cs);
    
    this.ls.on('pitch-detected', this.pitchDetected.bind(this));
    this.ls.on('volume', this.volumeChange.bind(this));

    this.ms.on('tick', (tick, denominator) => {
      log.debug("Tick: " + tick + " of " + denominator + " - " + this._noteCount);

      if (--this._noteCount <= 0) {
        if (!this._isFirstNote) {
          this.scoreView?.cursorNext();
        } 
        else {
          this._isFirstNote = false;
        }

        this.processCurrentNote(this.scoreView?.cursorNotes(), denominator);  
      }
    });

    this.ms.on('beat', (measure, beat) => { 
      // Do not record counting in...
      if (measure > 0)
        this.recording?.addEvent( new MetronomeEvent(measure, beat)); 
    });
  }

  private _isFirstNote: boolean = true;

  private _noteCount: number = 0;

  @ViewChild('scoreView')
  scoreView!: ScoreComponent;

  ngOnInit(): void {

    if (this.currentInstrument) {
      this.enableListeners(this.currentInstrument);
    }
    
  }

  ngAfterViewInit() {

    if (this.score) {
      this.scoreView.source = this.score.score;
      this.scoreView.render();
    }
  }

  async ngOnDestroy(): Promise<void> {

    await super.ngOnDestroy();

    if (this.currentInstrument) {
      await this.disableListeners(this.currentInstrument);
    }
  }

  async enableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(this._trackUNCPresses, instrument);    
  }

  async disableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(false, instrument);    
  }

  private _trackUNCPresses: boolean = false;

  private _midiListener?: (data: Uint8Array, receivedTime: number | undefined) => void;

  private _uncListener?: (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => void;

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {
  
    if (before) {
      await this.disableListeners(before);
    } 

    if (current) {
      await this.enableListeners(current);
    }
  }

  @Output()
  onKeysPressed = new EventEmitter<UNCKeyInfo>();

  @Input()
  set trackUNCPresses(flag: boolean) {

    if (this._trackUNCPresses != flag) {
      this._trackUNCPresses = flag;
      if (this.currentInstrument) this.setupUNCPresses(flag, this.currentInstrument);
    }
  }

  get trackUNCPresses(): boolean {
    return this._trackUNCPresses;
  }

  private _uncType: UNCType = UNCType.FACTORY; 

  get uncType(): UNCType {
    return this._uncType;
  }

  set uncType(value: UNCType) {
    this._uncType = value;
  }

  private _pressed: string[] = [];

  set pressed(value: string[]) {
    this._pressed = value;
  }

  get pressed(): string[] {
    return this._pressed;
  }

  private _isUNCActive: boolean = false;
  
  public onScoreLoaded(info: ScoreLoadedInfo) {
    this._rhythmDenominator = info.rhythmDenominator;
    this._rhythmNumerator = info.rhythmNumerator;
    this._bpm = info.bpm;

    log.debug("Score Info: " + JSON.stringify(info));
  }
  
  private async setupUNCPresses(flag: boolean, instrument: InstrumentConnection) {
      
    if (!flag) {
      instrument.stopUNCMessages();
  
      if (this._midiListener) {
        instrument.off('midi', this._midiListener);
        this._midiListener = undefined;
      }

      if (this._uncListener) {
        instrument.off('unc', this._uncListener);
        this._uncListener = undefined;
      }
    }
    else {
      let comp = this;

      this._uncListener = (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => {
        
        if (data.keyState) {
          log.info("UNC Press: " + data.keyState);
          let keys = decodeKeys(data.keyState);
          this._pressed = keys;
          this._uncType = data.type;
          this.onKeysPressed.emit({
            type: data.type,
            keys: keys, 
            sensors: data.keyState
          });

          this.recording?.addEvent(new KeyEvent(data.keyState, data.type));
        }
      }

      this._midiListener = (data: Uint8Array, receivedTime: number | undefined) => {

        var cmd = ((data[0] & 0xf0) >> 4);
        var channel = ((data[0] & 0x0f) + 1);

        switch (cmd) {
        case 0x8: // NOTE OFF
          var velocity = data[1];
          this.recording?.addEvent(new NoteEvent(data[1], false));
          break;

        case 0x9: // NOTE ON
          var note = getNoteByValue(data[1] % 12);
          var note = note + Math.floor(data[1] / 12);
          var velocity = data[2];
          this.recording?.addEvent(new NoteEvent(data[1], true));
          break;

        case 0xd:
          this.recording?.addEvent(new BreathEvent(data[1]));
          break;
        }
      }

      this._recording = new Recording();

      instrument.on("midi", this._midiListener);
      instrument.on("unc", this._uncListener);

      instrument.startUNCMessages();
    }    

    this._isUNCActive = flag;
  }

  private _lastRecording: Recording | undefined = undefined;

  private _recording: Recording | undefined = undefined;

  get recording(): Recording | undefined {
    return this._recording;
  }
    
  get isRecording() : boolean {
    return this._recording?.isRecording || false;
  }

  async togglePlay() {
    if (this.isRecording) {
      this.stopMetronome();
    }
    else {
      this.startMetronome();
    }
  }

  async stopMetronome() {
    
    this.trackUNCPresses = false;

    this.ms.stop();

    this._recording?.stopRecording();

    log.debug(this.recording?.toJSON() || '{}');
    
    if (this._recording) {
      await this.rs.addLocalRecording(this._recording);
    }
  
    this._lastRecording = this._recording;
    this._recording = undefined;
  }  

  private _score: Score | undefined;

  public get score(): Score | undefined {
    return this._score;
  }
  public set score(value: Score | undefined) {
    this._score = value;

    if (value && this.scoreView) {
      this.scoreView.source = value.score;
      this.scoreView.ngOnChanges({});
    }
  }


  async renderScore() {
    this.scoreView?.render();
  }

  async startMetronome() {
    
    this._recording = new Recording();

    this.trackUNCPresses = true;

    let ticks = this.scoreView.ticks || 0;
    let bpm = this.scoreView.bpm || 80;
    let rhythmDenominator = this.scoreView.rhythmDenominator || 4;
    let rhythmNumerator = this.scoreView.rhythmNumerator || 4;

    log.debug("Score Info: " + JSON.stringify({
      bpm: bpm,
      rhythmDenominator: rhythmDenominator,
      rhythmNumerator: rhythmNumerator,
      ticks: ticks
    }));

    this.ms.prepareMetronome(rhythmDenominator, rhythmNumerator, bpm, ticks);

    log.debug("Ticks per Minute: " + this.ms.ticksPerMinute);

    this.scoreView?.cursorShow(); 
    
    // Used as marker to record next note.
    this._isFirstNote = true;
    this._noteCount = 0;

    this._recording?.startRecording();

    if (this.score) {
      this._recording.scoreId = this.score.id;
    }

    this.ms.start(true);
  }

  get currentBeat(): number {
    return this.ms.beat;
  }

  get currentMeasure(): number {
    return this.ms.measure;
  }  

  private _bpm: number = 80;

  get bpm(): number {
    return this._bpm;
  }

  protected _rhythmDenominator: number = 4;

  get rhythmDenominator() : number {
    return this._rhythmDenominator;
  }

  protected _rhythmNumerator: number = 4;
  
  get rhythmNumerator() : number {
    return this._rhythmNumerator;
  }


  protected activeNote: NoteEntry | undefined = undefined;
  
  pitchDetected(pitch: PitchMessage | undefined) {
    
    if (pitch && pitch.index) {
      let octave = Math.floor((pitch.index - 11) / 12); 
      let note = this.smoothNote(noteStrings[pitch.index % 12]);

      log.debug("Detected: " + octave + " // " + note + " from " + JSON.stringify(pitch));

      if (octave && note)
        if ((this.activeNote?.note != note) || (this.activeNote?.octave != octave)) {
          this.flushVoicePitch();

            this.activeNote = {
              since: performance.now(),
              note: note,
              octave: octave,
              midi: pitch.index
            }
      }
    }
    else {
      this.flushVoicePitch();
    }
  }

  silenceThreshold: number = 10;

  volume: number = 0;
  
  volumeChange(volume: number) {
    if (this.volume < this.silenceThreshold) {
      //this.flushVoicePitch();
    }

    this.volume = volume;
  }

  protected flushVoicePitch() {
    if (this.activeNote) {
      let duration = performance.now() - this.activeNote.since;

      if (duration > 20) {
        log.debug("Flush: " + JSON.stringify(this.activeNote));
        this._recording?.addEvent(new ListenedNoteEvent(this.activeNote.midi, this.activeNote.since, duration));
      }
    }

    this.activeNote = undefined;
  }

  protected smoothingValue: 'none' | 'basic' | 'very' = 'very';

  protected smoothingCount: number = 0;
  protected lastNote: string = "";
  protected currentNote: string = "";

  protected smoothNote(newNote: string) : string {

    let smoothingCountThreshold = 0;

    if (this.smoothingValue === 'none') {
      smoothingCountThreshold = 0;
    } 
    else if (this.smoothingValue === 'basic') {
      smoothingCountThreshold = 5;
    } 
    else if (this.smoothingValue === 'very') {
      smoothingCountThreshold = 10;
    }
  
    // Check if this value has been within the given range for n iterations
    if (newNote === this.lastNote) {
      if (this.smoothingCount < smoothingCountThreshold) {
        this.smoothingCount++;
      } 
      else {
        this.currentNote = newNote;
        this.smoothingCount = 0;
      }
    } 
    else {
      this.lastNote = newNote;
      this.smoothingCount = 0;
    }

    return this.currentNote;
  }

 
  showRecordings() {
    let dialogOptions: NgbModalOptions = { 
      animation: true, 
      centered: true, 
      size: 'xl', 
      backdrop  : 'static', 
      scrollable: true, 
      modalDialogClass: 'rounded-4 shadow-2-strong',
      container: "#fullscreenContainer" 
    }

    let dialog = this.ds.open(TapeDialogComponent, dialogOptions);
    let comp = <TapeDialogComponent>dialog.componentInstance;

    comp.recording = this._lastRecording;
    comp.score = this.score;

  }

  protected processCurrentNote(notes: Note[] | undefined, ticks: number) {

    if (notes && notes.length > 0) {
      let note = notes[0];
      log.debug("Note-Measure: " + note.SourceMeasure?.MeasureNumber + " // Metronome-Measure: " + this.currentMeasure);

      if (note.Length.Numerator != 0) {
        this._noteCount = (ticks / note.Length.Denominator) * note.Length.Numerator;
      }
      else {
        this._noteCount = ticks;
      }

      if (!note.isRest()) {
        let ht = note.Pitch.getHalfTone();
        log.debug("Note: " + note.Pitch.ToString() + " // " + ht);
        let duration = 60000 / this.ms.ticksPerMinute * this._noteCount; 
        
        this._recording?.addEvent(new ScoreEvent(note.Pitch.getHalfTone(), duration))
      }

      log.debug("New note count " + this._noteCount);
    }    
  }
}
