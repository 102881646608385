import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Component } from '@angular/core';
import { rootViews } from 'src/app/helpers/log-config';
import {Recording} from 'src/app/model/recording';
import {KeyEvent, RecordingEvent} from "src/app/model/recording-events";
import {BehaviorSubject, max} from "rxjs";
import {getVersion} from "jest";

const log = rootViews.getChildCategory("learning");

type MouseEventCallback = (e: MouseEvent) => void;

/**
 * This implements the recorder view. Other than the score view will this
 * behave like an endless tape machine that can be stopped and started but
 * the overall length of the recording will always be the same.
 *
 * The endless scroll will then contain up to a fixed number of slices,
 * depending on the scale.
 */
@Component({
  selector: 'recording-view',
  templateUrl: './recording-view.component.html',
  styleUrls: ['./recording-view.component.scss']
})
export class RecordingViewComponent implements AfterViewInit, OnDestroy {

  constructor(protected cd: ChangeDetectorRef) {

    // this.threadDetailsSubscription = this.recording$
    //     .pipe(
    //         distinctUntilChanged(),
    //     )
    //     .subscribe(() =>
    //         this.cdkVirtualScrollViewport?.checkViewportSize(),
    //     );
  }

  @ViewChild(CdkVirtualScrollViewport)
  viewport!: CdkVirtualScrollViewport;

  @HostListener('window:resize', ['$event'])
  onResize(event : Event) {
    log.debug("Resize event received.");
    this.viewport.checkViewportSize();
  }

  // We need to trigger a resize a little bit with delay
  // to allow the view to pick up the container size.
  ngAfterViewInit() {
    setTimeout( this.checkViewportSize.bind(this), 200);

    this.intervalId = setInterval( this.adjustScrollable.bind(this), this.refreshTime);

    document.addEventListener('mousemove', this._mouseMoveHandler);
  }

  ngOnDestroy() {

    document.removeEventListener('mousemove', this._mouseMoveHandler);

    if (this.intervalId != null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  // Trigger the re-computation of the viewport size.
  protected checkViewportSize() {
    this.viewport?.checkViewportSize();
  }

  protected _sliceWidth : number = 100;

  @Input()
  set sliceWidth(width : number) {
    this._sliceWidth = width;
  }

  get sliceWidth() : number {
    return this._sliceWidth;
  }

  @Input()
  set maxRecordingLength(length: number) {
    this._maxRecordingLength = length;
  }

  get maxRecordingLength() : number {
    return this._maxRecordingLength;
  }

  private _maxRecordingLength : number = 25 * 1000;

  //#region Slice Management

  /** The duration covered by a single slice at scale 1.0 */
  private _sliceDuration : number = 1000;

  @Input()
  get sliceDuration() : number {
    return this._sliceDuration;
  }

  set sliceDuration(duration : number) {

    if (this._sliceDuration != duration) {
      this._sliceDuration = duration;
      this.updateSlicing();
    }
  }

  /** The scale for the depiction of the recording */
  private _scale: number = 1.0;

  @Input()
  get scale(): number {
    return this._scale;
  }

  set scale(scale: number) {

    if (this._scale != scale) {
      this._scale = scale;
      this.updateSlicing();
    }
  }

  slices$ = new BehaviorSubject(
      Array.from({length: 20}).map((_, i) => i + 1)
  )

  resetSlices() {
    let slices = Array.from({length: 20}).map((_, i) => i + 1);
    this.slices$.next(slices);
  }

  addSlices(slices : number[], maxLength : number) : number {
    let items = this.slices$.getValue();
    items.push(...slices);
    if (items.length > maxLength) {
      items = items.slice(items.length - maxLength);
    }
    log.debug("Now having " + items.length + " slices ");
    this.slices$.next(items);

    return items[0];
  }

  /**
   * Compute the number of slices needed to represent the recording
   */
  private updateSlicing() {

    let duration = Math.max(0, this.lastTime - this.startTime);
    let minSlices = Math.ceil(duration / (this.sliceDuration / this.scale)) + 3;

    let slices = this.slices$.getValue();

    let currentLength = slices.length;
    let lastSlice = slices[slices.length - 1];

    if (currentLength < minSlices) {
      this.prepareSlices(minSlices - currentLength, lastSlice).then( () => {
        this.cd.detectChanges();
      });
    }
    else {
      this.cd.detectChanges();
    }

    this.checkViewportSize();
  }

  private async prepareSlices(length: number, lastSlice: number) : Promise<void> {
    let maxSlices = Math.ceil(this._maxRecordingLength / (this.sliceDuration / this.scale));
    let additional = Array.from({length: length}).map((_, i) => (lastSlice + i + 1));
    let firstSlice = this.addSlices( additional, maxSlices + 10 );
    this.startTime = this.beginTime + firstSlice * (this.sliceDuration / this.scale);
  }

  //#endregion

  //#region Mouse Panning Implementation
  private _pos = { top: 0, left: 0, x: 0, y: 0 };
  private _dragging : boolean = false;

  private _mouseMoveHandler = this.mouseMoveHandler.bind(this);;
  private _mouseUpHandler = this.mouseUpHandler.bind(this);

  public mouseDownHandler(e: MouseEvent) {

    if (e.button != 0) return;

    this._pos = {
        // The current scroll
        left: this.viewport.measureScrollOffset("start") || 0, // this.viewport.measureViewportOffset("left"),
        top: this.viewport.measureViewportOffset("top"),

        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    this._dragging = true;

    document.addEventListener('mouseup', this._mouseUpHandler);

    // Change the cursor and prevent user from selecting the text
    let ele = e.target as HTMLElement;

    if (ele) {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
    }
  }

  public mouseMoveHandler(e: MouseEvent) {
    // How far the mouse has been moved
    const dx = e.clientX - this._pos.x;

    if (this._dragging) {
      this.viewport.scrollTo({
        left: this._pos.left - dx
      })
    }
    else {
      let scrollView = this.viewport.elementRef.nativeElement;
      let mouseTime : number | undefined = undefined;
      let sliceNumber : number | undefined = undefined;

      if ((e.pageX >= scrollView.offsetLeft && e.pageX <= (scrollView.offsetLeft + scrollView.offsetWidth)) &&
          (e.pageY >= scrollView.offsetTop  && e.pageY <= (scrollView.offsetTop + scrollView.offsetHeight))) {

        let scaledSliceDuration = this.sliceDuration / this.scale;

        let left = this.viewport.measureScrollOffset("left");
        let mouseX = (e.pageX - this.viewport.elementRef.nativeElement.offsetLeft)
        let offset = scaledSliceDuration * (mouseX / this.sliceWidth);

        sliceNumber = Math.ceil((left + mouseX) / this.sliceWidth);
        offset = mouseX - this.sliceWidth * (sliceNumber - 1) + left;

        mouseTime = offset;
      }

      if (this.mouseMarkerTime != mouseTime) {
        this.mouseMarkerTime = mouseTime;
        this.markerSlice = sliceNumber;

        this.cd.detectChanges();
      }
    }
  }

  public mouseUpHandler(e: MouseEvent) {

    if (this._mouseUpHandler)
      document.removeEventListener('mouseup',this._mouseUpHandler);

    let ele = e.target as HTMLElement;

    if (ele) {
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    }

    this._dragging = false;
  }

  @Output()
  hoveredEvents = new EventEmitter<readonly RecordingEvent<any>[]>();

  public mouseOverEvents(events : RecordingEvent<any>[]) {
    this.hoveredEvents.emit(events);
  }

  protected markerSlice: number | undefined;
  protected mouseMarkerTime: number | undefined;

  //#endregion

  //#region Recording Management


  private _transposeInstrument: number = -4;

  @Input()
  public get transposeInstrument(): number {
    return this._transposeInstrument;
  }

  public set transposeInstrument(value: number) {
    this._transposeInstrument = value;

    this.cd.detectChanges();
  }

  private _recording: Recording | undefined = undefined;

  @Input()
  public get recording(): Recording | undefined {
    return this._recording;
  }

  public set recording(recording: Recording | undefined) {

    if (this._recording == recording) {
      return;
    }

    if (this._recording) {
      recording?.removeListener('eventsAdded', this._recordingListener);
    }

    this._recording = recording;
    this.resetSlices();

    if (recording) {
      this.updateSlicing();
      recording.addListener('eventsAdded', this._recordingListener);
    }
  }

  private readonly _recordingListener = this.recordingEventAdded.bind(this);

  //#endregion

  // Refresh-interval for the scrollable region in milliseconds
  protected refreshTime : number = 50;

  protected intervalId : any | null = null;

  protected beginTime : number = 0;
  protected startTime : number = this.beginTime;
  protected lastTime : number = performance.now();

  protected adjustScrollable() {

    if (this._dragging) return;

    if (this._recording) {

      let time = this._recording.relativeTime;

      // if (this._recording.isPaused) return;

      this.lastTime = time;
      // this._recording.trimRecordingFromStart(Math.max(0, time - this._maxRecordingLength)).then( () => {
      //       log.debug("Truncated recording");
      //     });

      if (this._recording) {
        this._recording.adjustRunningEvents(time)
      }

      if (this._recording.isPaused) return;

      let offset = time;
      let scaledSliceDuration = this.sliceDuration / this.scale;

      this.updateSlicing();
      let firstSlice = this.slices$.getValue()[0];
      let leftOffset = (offset / scaledSliceDuration - firstSlice) * this.sliceWidth;

      let currentOffset = this.viewport.scrollable.measureScrollOffset('left');
      let width = this.viewport.scrollable.measureViewportSize('horizontal');

      if (leftOffset > (width * 0.9)) {
        leftOffset = leftOffset - width * 0.9;
      }
      else {
        leftOffset = 0;
      }

      this.debugLeft = leftOffset;

      this.viewport.scrollTo({
        left: leftOffset,
        behavior: (leftOffset > currentOffset ? "smooth" : "instant")
      });
    }
    // else if (this.intervalId != null) {
    //   clearInterval(this.intervalId);
    //   this.intervalId = null;
    // }
  }

  protected debugLeft : number = 0;

  protected recordingEventAdded(recording : Recording, events : RecordingEvent<any>[]) {
    if (this.intervalId == null) {
      this.intervalId = setInterval( this.adjustScrollable.bind(this), this.refreshTime);
    }
  }

}
