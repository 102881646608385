import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { InstrumentViewComponentBaseComponent, UNCKeyInfo } from '../../helpers/instrument-view-component-base.component';
import { HardwareTypes } from '../../model/device-info';
import { InstrumentInfo } from '../../model/unc-types';

export enum SelectionMode {
  single,

  multi
}
@Component({
  selector: 'app-instrument-diagram',
  templateUrl: './instrument-diagram.component.html',
  styleUrls: ['./instrument-diagram.component.scss']
})
export class InstrumentDiagramComponent implements OnDestroy, OnInit {

  hwTypes = HardwareTypes

  private _supportKeySelection: boolean = false;
  private _trackKeyPresses: boolean = false;
  private _trackUNCPresses: boolean = false;
  private _printing: boolean = false;
  private _hardwareType: HardwareTypes = HardwareTypes.NONE
  private _hardwareVersion: number = 1
  private _useStateColors: boolean = true;
  
  @ViewChild('instrumentView')
  instrumentView!: InstrumentViewComponentBaseComponent;

  get instrument() : InstrumentInfo | undefined {
    return this.instrumentView?.instrument;
  }

  async ngOnInit() {
    await this.updateUNCPressesTracking(true);
  }

  async ngOnDestroy() {
    await this.updateUNCPressesTracking(false);
  }

  async updateKeyPressesTracking(flag: boolean) {
    this.instrumentView?.updateKeyPressesTracking(flag);
  }

  async updateUNCPressesTracking(flag: boolean) {
    this.instrumentView?.updateUNCPressesTracking(flag);
  }

  @Input()
  set printing(value: boolean) {
    this._printing = value;
  }

  public get printing() : boolean {
    return this._printing;
  }

  @Input()
  set supportKeySelection(flag: boolean) {
    this._supportKeySelection = flag;
  }

  get supportKeySelection(): boolean {
    return this._supportKeySelection;
  }

  @Input()
  set trackKeyPresses(flag: boolean) {
    this._trackKeyPresses = flag;
  }

  get trackKeyPresses(): boolean {
    return this._trackKeyPresses;
  }

  @Input()
  set trackUNCPresses(flag: boolean) {
    this._trackUNCPresses = flag;
  }

  get trackUNCPresses(): boolean {
    return this._trackUNCPresses;
  }

  @Input()
  set useStateColors(flag: boolean) {
    this._useStateColors = flag;
  }

  get useStateColors(): boolean {
    return this._useStateColors;
  }

  @Input()
  set instrumentType(type: HardwareTypes) {
    this._hardwareType = type;
  }
    get instrumentType() : HardwareTypes {
    return this._hardwareType;
  }


  @Input()
  set instrumentVersion(version: number) {
    this._hardwareVersion = version;
  }
    get instrumentVersion() : number {
    return this._hardwareVersion;
  }

  get selected(): string[] {
    return this.instrumentView.selected;
  }

  set selected(keys: string[]) {
    this.instrumentView.selected = keys;
  }
  
  set pressed(value: string[]) {
    this.instrumentView.pressed = value;
  }

  get pressed(): string[] {
    return this.instrumentView.pressed;
  }

  set pressure(value: number) {
    this.instrumentView.pressure = value;
  }

  get pressure(): number {
    return this.instrumentView.pressure;
  }

  @Output()
  onKeysPressed = new EventEmitter<UNCKeyInfo>();

  @Output()
  onKeyClicked = new EventEmitter<string>();

  forwardKeysPressed(event: UNCKeyInfo) {
    this.onKeysPressed.emit(event);
  }

  forwardKeyClicked(event: string) {
    this.onKeyClicked.emit(event);
  }
}
