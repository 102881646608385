// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recording-display {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
}

.recording-display .recording-viewport {
  height: 465px;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-y: hidden;
}

.recording-slice {
  float: left;
  white-space: normal;
  display: inline-block;
  height: 450px;
  width: 100px;
}

.fade {
  border: 1ps solid blue;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  background-blend-mode: luminosity;
}`, "",{"version":3,"sources":["webpack://./src/app/views/recorder/recording-view/recording-view.component.scss"],"names":[],"mappings":"AAKA;EACE,WAAA;EACA,YAAA;EAEA,qBAAA;AALF;;AASA;EACE,aAAA;EAGA,qBAAA;EAGA,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,kBAAA;AAXF;;AAcA;EACE,WAAA;EACA,mBAAA;EACA,qBAAA;EAEA,aA9BY;EA+BZ,YAhCW;AAoBb;;AAgBA;EACE,sBAAA;EACA,eAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;EACA,iCAAA;AAbF","sourcesContent":["\n$sliceWidth: 100px;\n$sliceHeight: 450px;\n\n\n.recording-display {\n  width: 100%;\n  height: 100%;\n\n  scrollbar-width: none;\n  // background: #2a7aaf;\n}\n\n.recording-display .recording-viewport {\n  height: calc($sliceHeight + 15px);\n  // width: calc(100% - $sliceWidth);\n\n  scrollbar-width: none;\n  // background: #4b6b8c;\n\n  display: flex;\n  flex-direction: row;\n\n  white-space: nowrap;\n  overflow-y: hidden;\n}\n\n.recording-slice {\n  float: left;\n  white-space: normal;\n  display: inline-block;\n\n  height: $sliceHeight;\n  width: $sliceWidth;\n}\n\n\n.fade {\n  border: 1ps solid blue;\n  position: fixed;\n  right: 0;\n  top:0;\n  bottom: 0;\n  width:20px;\n  background-blend-mode: luminosity;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
