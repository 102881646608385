
export interface MidiNoteConfig {

    /**
     * MIDI Note
     */
    code: number,

    /**
     * Note name 
     */
    note: 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B',

    sharp?: boolean;

    oct: number;

    offset: number;
}

export const MIDI_NOTES = <MidiNoteConfig[]>[
    {	code: 36,	note: 'C',	                oct: 0, offset: 0 },
    {	code: 37,	note: 'C',	sharp: true,    oct: 0, offset: 0 },
    {	code: 38,	note: 'D',                  oct: 0, offset: 1 },
    {	code: 39,	note: 'D',	sharp: true,    oct: 0, offset: 1 },
    {	code: 40,	note: 'E',                  oct: 0, offset: 2 },
    {	code: 41,	note: 'F',                  oct: 0, offset: 3 },
    {	code: 42,	note: 'F',	sharp: true,    oct: 0, offset: 3 },
    {	code: 43,	note: 'G',                  oct: 0, offset: 4 },
    {	code: 44,	note: 'G',	sharp: true,    oct: 0, offset: 4 },
    {	code: 45,	note: 'A',                  oct: 0, offset: 5 },
    {	code: 46,	note: 'A',	sharp: true,    oct: 0, offset: 5 },
    {	code: 47,	note: 'B',                  oct: 0, offset: 6 },
    {	code: 48,	note: 'C',	                oct: 1, offset: 0 },
    {	code: 49,	note: 'C',	sharp: true,	oct: 1, offset: 0 },
    {	code: 50,	note: 'D',	                oct: 1, offset: 1 },
    {	code: 51,	note: 'D',	sharp: true,	oct: 1, offset: 1 },
    {	code: 52,	note: 'E',	                oct: 1, offset: 2 },
    {	code: 53,	note: 'F',	                oct: 1, offset: 3 },
    {	code: 54,	note: 'F',	sharp: true,	oct: 1, offset: 3 },
    {	code: 55,	note: 'G',	                oct: 1, offset: 4 },
    {	code: 56,	note: 'G',	sharp: true,	oct: 1, offset: 4 },
    {	code: 57,	note: 'A',	                oct: 1, offset: 5 },
    {	code: 58,	note: 'A',	sharp: true,	oct: 1, offset: 5 },
    {	code: 59,	note: 'B',	                oct: 1, offset: 6 },
    {	code: 60,	note: 'C',	                oct: 2, offset: 0 },
    {	code: 61,	note: 'C',	sharp: true,	oct: 2, offset: 0 },
    {	code: 62,	note: 'D',	                oct: 2, offset: 1 },
    {	code: 63,	note: 'D',	sharp: true,	oct: 2, offset: 1 },
    {	code: 64,	note: 'E',	                oct: 2, offset: 2 },
    {	code: 65,	note: 'F',	                oct: 2, offset: 3 },
    {	code: 66,	note: 'F',	sharp: true,	oct: 2, offset: 3 },
    {	code: 67,	note: 'G',	                oct: 2, offset: 4 },
    {	code: 68,	note: 'G',	sharp: true,	oct: 2, offset: 4 },
    {	code: 69,	note: 'A',	                oct: 2, offset: 5 },
    {	code: 70,	note: 'A',	sharp: true,	oct: 2, offset: 5 },
    {	code: 71,	note: 'B',	                oct: 2, offset: 6 },
    {	code: 72,	note: 'C',	                oct: 3, offset: 0 },
    {	code: 73,	note: 'C',	sharp: true,	oct: 3, offset: 0 },
    {	code: 74,	note: 'D',	                oct: 3, offset: 1 },
    {	code: 75,	note: 'D',	sharp: true,	oct: 3, offset: 1 },
    {	code: 76,	note: 'E',	                oct: 3, offset: 2 },
    {	code: 77,	note: 'F',	                oct: 3, offset: 3 },
    {	code: 78,	note: 'F',	sharp: true,	oct: 3, offset: 3 },
    {	code: 79,	note: 'G',	                oct: 3, offset: 4 },
    {	code: 80,	note: 'G',	sharp: true,	oct: 3, offset: 4 },
    {	code: 81,	note: 'A',	                oct: 3, offset: 5 },
    {	code: 82,	note: 'A',	sharp: true,	oct: 3, offset: 5 },
    {	code: 83,	note: 'B',	                oct: 3, offset: 6 },
    {	code: 84,	note: 'C',	                oct: 4, offset: 0 },
    {	code: 85,	note: 'C',	sharp: true,	oct: 4, offset: 0 },
    {	code: 86,	note: 'D',	                oct: 4, offset: 1 },
    {	code: 87,	note: 'D',	sharp: true,	oct: 4, offset: 1 },
    {	code: 88,	note: 'E',	                oct: 4, offset: 2 },
    {	code: 89,	note: 'F',	                oct: 4, offset: 3 },
    {	code: 90,	note: 'F',	sharp: true,	oct: 4, offset: 3 },
    {	code: 91,	note: 'G',	                oct: 4, offset: 4 },
    {	code: 92,	note: 'G',	sharp: true,	oct: 4, offset: 4 },
    {	code: 93,	note: 'A',	                oct: 4, offset: 5 },
    {	code: 94,	note: 'A',	sharp: true,	oct: 4, offset: 5 },
    {	code: 95,	note: 'B',	                oct: 4, offset: 6 },
]

export function getMidiNoteForCode(code: number | null | undefined) : MidiNoteConfig | undefined {
    return MIDI_NOTES.find( (v) => { 
        return v.code == code; 
    });
}


export interface NoteEntry {
    note: string;
    octave: number;
    since: number;
    midi: number;
}

export const noteStrings = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];


enum MidiNotes {
    "B"   = 11,
    "Bb"  = 10,
    "A#"  = 10,
    "A"   =  9,
    "Ab"  =  8,
    "G#"  =  8,
    "G"   =  7,
    "Gb"  =  6,
    "F#"  =  6,
    "F"   =  5,
    "E"   =  4,
    "Eb"  =  3,
    "D#"  =  3,
    "D"   =  2,
    "Db"  =  1,
    "C#"  =  1,
    "C"   =  0,
}

export function getNoteByValue(value: number): string {
    const indexOfS = Object.values(MidiNotes).indexOf(value as unknown as MidiNotes);
    const key = Object.keys(MidiNotes)[indexOfS];

    return key;
}
