import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { rootViews } from 'src/app/helpers/log-config';
import { Recording } from 'src/app/model/recording';

const log = rootViews.getChildCategory("learning");

type MouseEventCallback = (e: MouseEvent) => void;

@Component({
  selector: 'app-tape-view',
  templateUrl: './tape-view.component.html',
  styleUrls: ['./tape-view.component.scss']
})
export class TapeViewComponent {

  constructor(protected cd: ChangeDetectorRef) {

  }

  @ViewChild(CdkVirtualScrollViewport)
  viewport!: CdkVirtualScrollViewport;

  private _slice: number = 0;

  @Input()
  public get slice(): number {
    return this._slice;
  }
  public set slice(value: number) {
    this._slice = value;
  }

  private _sliceInterval: number = 500;
  
  @Input()
  public get sliceInterval(): number {
    return this._sliceInterval;
  }

  public set sliceInterval(value: number) {
    this._sliceInterval = value;
  }

  private _recording: Recording | undefined = undefined;

  @Input()
  public get recording(): Recording | undefined {
    return this._recording;
  }

  public set recording(recording: Recording | undefined) {
    this._recording = recording;

    this.updateSlicing();
  }

  private updateSlicing() {
    let slices = Math.ceil((this._recording?.duration || 0) / (this._sliceInterval * this.scale)); 

    var offset = 1;

    this.slices = Array.from({length: slices}).map((_, i) => (i + offset));

    this.cd.detectChanges();
  }

  slices = Array.from({length: 100000}).map((_, i) => i);

  //#region Mouse Panning Implementation
  private _pos = { top: 0, left: 0, x: 0, y: 0 };

  private _mouseMoveHandler: MouseEventCallback | null = null;
  private _mouseUpHandler: MouseEventCallback | null = null;

  public mouseDownHandler(e: MouseEvent) {

    if (e.button != 0) return;

    this._pos = {
        // The current scroll
        left: this.viewport.measureScrollOffset("start") || 0, // this.viewport.measureViewportOffset("left"),
        top: this.viewport.measureViewportOffset("top"),

        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    this._mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this._mouseUpHandler = this.mouseUpHandler.bind(this);

    document.addEventListener('mousemove', this._mouseMoveHandler);
    document.addEventListener('mouseup', this._mouseUpHandler);

    // Change the cursor and prevent user from selecting the text
    let ele = e.target as HTMLElement;

    if (ele) {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
    }
  }

  public mouseMoveHandler(e: MouseEvent) {
    // How far the mouse has been moved
    const dx = e.clientX - this._pos.x;
    const dy = e.clientY - this._pos.y;

    this.viewport.scrollTo({
      left: this._pos.left - dx
    })
  }

  private _transposeVoice: number = +6;
  
  @Input()
  public get transposeVoice(): number {
    return this._transposeVoice;
  }
  public set transposeVoice(value: number) {
    this._transposeVoice = value;
  }
  
  private _transposeInstrument: number = -4;

  @Input()
  public get transposeInstrument(): number {
    return this._transposeInstrument;
  }

  public set transposeInstrument(value: number) {
    this._transposeInstrument = value;

    this.cd.detectChanges();
  }

  
  public mouseUpHandler(e: MouseEvent) {
    if (this._mouseMoveHandler)
      document.removeEventListener('mousemove', this._mouseMoveHandler);
  
    if (this._mouseUpHandler)
      document.removeEventListener('mouseup',this._mouseUpHandler);

    let ele = e.target as HTMLElement;

    if (ele) {
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    }
  }  

  //#endregion

  private _scale: number = 1.0;

  @Input()
  get scale(): number {
    return this._scale;
  }

  set scale(value: number) {
    this._scale = value;

    this.updateSlicing();
  }

}
