import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-midi-playback-diagram',
  templateUrl: './midi-playback-diagram.component.html',
  styleUrl: './midi-playback-diagram.component.scss'
})
export class MidiPlaybackDiagramComponent implements OnInit {


  ngOnInit(): void {
  }
}
