// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  overflow: hidden;
  margin-right: 20px;
}

.recorder {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

recording-view {
  width: calc(100% - 120px);
  display: contents;
  flex-grow: 1;
}

.instrument {
  display: inline-flex;
  vertical-align: top;
  width: 110px;
  flex-grow: 0;
  margin-top: 40px;
  margin-right: 0;
}

.instrument app-instrument-diagram {
  height: 465px;
  width: 90px;
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/app/views/recorder/recorder-view/recorder-view.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EAEA,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,iBAAA;EACA,YAAA;AAAF;;AAGA;EACE,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EAEA,gBAAA;EACA,eAAA;AADF;;AAKA;EACE,aAAA;EACA,WAAA;EACA,oBAAA;AAFF","sourcesContent":["div {\n  overflow: hidden;\n\n  margin-right: 20px;\n}\n\n.recorder {\n  width: 100%;\n  display: flex;\n  column-gap: 15px;\n}\n\nrecording-view {\n  width: calc(100% - 120px);\n  display: contents;\n  flex-grow: 1;\n}\n\n.instrument {\n  display: inline-flex;\n  vertical-align: top;\n  width: 110px;\n  flex-grow: 0;\n\n  margin-top: 40px;\n  margin-right: 0;\n}\n\n\n.instrument app-instrument-diagram {\n  height: 465px;\n  width: 90px;\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
