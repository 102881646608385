import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { InstrumentStatusComponentBase } from 'src/app/helpers/instrument-status-component-base';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { InstrumentState } from 'src/app/model/instrument-state';
import { ConnectionService } from 'src/app/services/connection.service';
import {FeatureSupportService} from "../../services/feature-support.service";

@Component({
  selector: 'app-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent extends InstrumentStatusComponentBase  implements OnInit {

  @Input()
  firmwares: FirmwareInfo[] = [];

  @Input()
  allowUpdates: boolean = false;
  
  bm20FirmwareMode: boolean = false;

  constructor(
    protected fs: FeatureSupportService,
    protected cs: ConnectionService,
    private cd: ChangeDetectorRef) { 

    super(cs);
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.cs.emeoConnection }, ...features);
  }

  ngOnInit(): void {
  }

  toggleBM20FirmwareMode() {

    if (this.currentInstrument) {
      if (this.bm20FirmwareMode) {
        this.currentInstrument.disableBM20FirmwareMode();
      }
      else {
        this.currentInstrument.enableBM20FirmwareMode();
      }
    }
  }

  override async onStateUpdated(state: InstrumentState) {
    this.bm20FirmwareMode = state.bm20FirmwareMode;
    this.cd.detectChanges();
  }
}
