// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  height: 100%;
}

header {
  background-color: #000;
  width: 100%;
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  padding: 10px;
}

.logo svg {
  height: 39px;
}

.content {
  height: 100vh;
  position: fixed;
  top: 60px;
}

.content.presenter {
  top: 0;
}

.button {
  padding: 0 10px;
}

.status {
  font-size: 18pt;
}

.bi svg {
  width: 20pt;
  height: 20pt;
  margin-bottom: 5pt;
}

.fullscreen-control {
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;;AAIA;EACE,sBAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AADF;;AAIA;EACG,MAAA;AADH;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;AADF","sourcesContent":["\n\nbody, html {\n  height: 100%;\n}\n\nheader {\n  background-color: #000;\n  width: 100%;\n  position: fixed;\n  display: block;\n  left: 0;\n  top: 0;\n  padding: 10px;\n}\n\n.logo svg {\n  height: 39px;\n}\n\n.content {\n  height: 100vh;\n  position: fixed;\n  top: 60px;\n}\n\n.content.presenter {\n   top: 0;\n }\n\n.button {\n  padding: 0 10px;\n}\n\n.status {\n  font-size: 18pt;\n}\n\n.bi svg {\n  width: 20pt;\n  height: 20pt;\n  margin-bottom: 5pt;\n}\n\n.fullscreen-control {\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
