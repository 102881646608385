// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  height: 100% !important;
  width: 100% !important;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/views/recorder/recording-slice/recording-slice.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,sBAAA;EAEA,qBAAA;AAAF","sourcesContent":["div {\n  height: 100% ! important;\n  width: 100% ! important;\n\n  display: inline-block;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
