import { rootModel } from "../helpers/log-config";
import { Type, instanceToPlain, plainToInstance, Expose } from 'class-transformer';
import {TypedEmitter} from "tiny-typed-emitter";

import { EventBase, RecordingEvent, AnnotationEvent, BreathEvent, KeyEvent, ListenedNoteEvent, MetronomeEvent, NoteEvent, ScoreEvent, PauseEvent, RecordingTime } from 'src/app/model/recording-events';

const log = rootModel.getChildCategory("recording");

export interface RecordingEvents {
  'eventsAdded': (recording : Recording, event : RecordingEvent<any>[]) => void;
  'eventsUpdated': (recording : Recording, events : RecordingEvent<any>[]) => void;
}

export class Recording extends TypedEmitter<RecordingEvents> {

  constructor() {
    super();

    this.setMaxListeners(40);
    log.debug("New recording created");
  }

  @Expose({ name: 'id' })
  private _id: string = "";
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  
  @Expose({ name: 'score_id' })
  private _scoreId: string = "";
  public get scoreId(): string {
    return this._scoreId;
  }
  public set scoreId(value: string) {
    this._scoreId = value;
  }

  @Expose({ name: 'date' })
  private _date: Date = new Date();
  public get date(): Date {
    return this._date;
  }
  public set date(value: Date) {
    this._date = value;
  }

  // Sequential list of events
  @Type(() => EventBase, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: NoteEvent, name: 'note' },
        { value: MetronomeEvent, name: 'metronome' },
        { value: KeyEvent, name: 'key' },
        { value: BreathEvent, name: 'breath' },
        { value: ScoreEvent, name: 'score' },
        { value: ListenedNoteEvent, name: 'listen' },
        { value: PauseEvent, name: 'pause' },
        { value: AnnotationEvent, name: 'anno' },
        { value: String, name: 'str' }
      ],
    },
  })

  private batchSize : number = 500;

  @Expose({name: 'events'})
  private _events : RecordingEvent<any>[] = new Array(this.batchSize);

  private _eventIndex : number = 0;

  private expandEvents() {
    let additional = new Array(this.batchSize);
    this._events.push( ...additional);
  }

  public get length() : number {
    return this._events.length;
  }

  get events(): ReadonlyArray<RecordingEvent<any>> {
    return this._events;
  }

  interval(start: number, duration: number) : ReadonlyArray<RecordingEvent<any>> {
    return this._events
        .filter( event => event != undefined )
        .filter( event => ((event.relativeStart <= (start + duration)) && (event.relativeEnd >= start)) );
  }
  
  private _isProcessed : boolean = false;

  private _isRecording : boolean = false;

  get isRecording(): boolean {
    return this._isRecording;
  }

  private _firstTimestamp: RecordingTime = -1;

  public get firstTimestamp(): RecordingTime {
    return this._firstTimestamp;
  }
  
  private _lastTimestamp: RecordingTime = 0;

  public get lastTimestamp(): RecordingTime {
    return this._lastTimestamp;
  }

  public get duration(): number {
    if (this._firstTimestamp == -1) {
      return -1;
    }

    return (this._lastTimestamp - this._firstTimestamp);
  }

  //#region Ongoing Event Handling
  private _lastNotes : { [ code : number ] : NoteEvent | null } = {};

  private _lastKey : KeyEvent | null = null;

  private _lastBreath : BreathEvent | null = null;
  //#endregion

  //#region Persistence
  public toJSON() : string {
    return JSON.stringify(instanceToPlain(this));
  }

  public static fromJSON(js : string): Recording | null {
    let result = plainToInstance(Recording, js);

    if (result) {
      result._isProcessed = false;
      result.postProcess();
    }
    return result;
  }
  //#endregion

  // Put an event into the event queue and maintain additional indexing.
  public addEvent<T extends RecordingEvent<any>>(event: T) : T {

    if (!this._isRecording) {
      return event;
    }

    this.continue();

    event.timeOffset = this._currentTimeOffset;

    let ts = event.relativeStart;

    if (ts < this.lastTimestamp) {
      log.error("Sequence error");
      return event;
    }

    // log.debug("Event " + event.relativeStart);

    event.duration = Math.max(event.duration, 0.0001);

    if (event instanceof NoteEvent) {
      let ne = event as NoteEvent;

      // log.debug("NOTE: " + ne.note + " > " + (ne.isNoteOn ? "ON" : "OFF"));

      // Do we have an ongoing note event for this note?

      let note = this._lastNotes[ ne.note ];
      if (note) {
        let endTime = ts - 0.005;
        note.duration = endTime - note.relativeStart;
        this._lastNotes[ ne.note ] = null;
        // this.addEvent(new NoteEvent(note.note, false, ts - 0.0025));
        this.emit('eventsUpdated', this, [ note ]);
      }

      if (ne.isNoteOn) {
        this._lastNotes[ ne.note ] = ne;
      }
      else {
        this._lastNotes[ ne.note ] = null;
        return event;
      }
    }

    if (event instanceof KeyEvent) {
      let ke = event as KeyEvent;

      if (this._lastKey != null) {
        let endTime = ts - 0.005;
        this._lastKey.duration = endTime - this._lastKey.relativeStart;
      }

      this._lastKey = ke;
    }

    if (event instanceof BreathEvent) {
      let be = event as BreathEvent;

      if (this._lastBreath != null) {
        let endTime = event.relativeEnd - 0.005;
        this._lastBreath.duration = endTime - this._lastBreath.relativeStart;
      }

      this._lastBreath = be;
    }

    if (this.firstTimestamp === 0) {
      this._firstTimestamp = event.relativeStart;
    }
    else {
      this._firstTimestamp = Math.min(this.firstTimestamp, event.relativeStart);
    }

    this._lastTimestamp = Math.max(this.lastTimestamp, event.relativeStart);

    if (this._eventIndex >= this._events.length) {
      this.expandEvents();
    }

    this._events[this._eventIndex++] = event;
    this.emit('eventsAdded', this, [ event ]);

    return event;
  }

  public startRecording(paused : boolean = false) : boolean {
    if (this._isProcessed) {
      return false;
    }

    if (!this._isRecording) {
       this._firstTimestamp = 0;
       this._currentTimeOffset = performance.now();
    }

    if (paused) {
      this.pause();
    }

    this._lastNotes = {};
    this._isRecording = true;

    return true;
  }

  //#region Pause-Continue handling

  protected _pauseEvent : PauseEvent | null = null;

  protected _currentTimeOffset : RecordingTime = performance.now();

  public get relativeTime() : RecordingTime {

    let ts = performance.now();

    if (this._pauseEvent == null) {
      return ts - this._currentTimeOffset;
    }
    else {
      return this._pauseEvent.relativeStart + 500
    }
  }

  public pause(backward : number = 0) {
    if (this._pauseEvent) return;

    this._pauseEvent = this.addEvent(new PauseEvent(performance.now(), 250));

    this.terminateActiveEvents();
  }

  public get isPaused() : boolean {
    return (this._pauseEvent != null);
  }

  public continue() {
    if (this._pauseEvent == null) return;

    let ts = performance.now();

    this._currentTimeOffset = ts - (this._pauseEvent.relativeStart + this._pauseEvent.duration);
    this._pauseEvent = null;

  }

  //#endregion

  public async trimRecordingFromStart(time : RecordingTime) : Promise<void> {

    // log.debug("Cutoff:" + time);
    // let events = this._events;
    //
    // let index = events.findIndex( (e) => { return e.relativeStart >= time; } );
    // log.debug("> BEFORE: " + events.length);
    // if (index > 10) {
    //   let event = events[index];
    //
    //   this._firstTimestamp = event.relativeStart;
    //   this._events = events.slice(index, events.length);
    //   log.debug("> BEFORE: " + this._events.length);
    // }
  }

  // Post-Processing of the stored data to provide 
  // duration values and associations between the events.
  public stopRecording() {

    // Protect against double stops
    if (!this._isRecording) {
      return;
    }

    let endTime = this.terminateActiveEvents();

    this._lastNotes = {};
    this._isRecording = false;
    this._lastTimestamp = endTime;

    this.postProcess();
  }

  protected postProcess() {
    if (this._isProcessed) {
      return;
    }

    // // Ensure all events are in the right sequence
    // this._events.sort((a, b) => a.time - b.time);
    //
    // if (this._firstTimestamp == -1) {
    //   this._firstTimestamp = this._events.at(0)?.startTime || -1;
    // }
    //
    // if (this._lastTimestamp == -1) {
    //   this._lastTimestamp = this._events[this._events.length - 1]?.startTime || -1;
    // }

    let measure = 0;
    this._events.forEach( (event, index, events) => {
      measure = event.assignMeasure(measure);
      event.process(index, events);
    });

    this._isProcessed = true;
  }

  adjustRunningEvents(time: number) {
    let events : RecordingEvent<any>[] = [];

    Object.values(this._lastNotes).forEach( (note) => {
      if (note != null) {
        note.duration = time - note.relativeStart;
        events.push(note);
      }
    });

    if (this._lastBreath) {
      this._lastBreath.duration = time - this._lastBreath.relativeStart;
      events.push(this._lastBreath);
    }

    if (this._lastKey) {
      this._lastKey.duration = time - this._lastKey.relativeStart;
      events.push(this._lastKey);
    }

    if (events.length > 0) {
      this.emit('eventsUpdated', this, events);
    }
  }

  private terminateActiveEvents() : RecordingTime {

    let endTime = performance.now() - this._currentTimeOffset;

    let events : RecordingEvent<any>[] = [];

    Object.values(this._lastNotes).forEach( (note) => {
      if (note != null) {
        note.duration = endTime - note.relativeStart;
        events.push(note);
      }
    });

    this._lastNotes = {}
    this._lastKey = this.terminateEvent(this._lastKey, endTime, events);
    this._lastBreath = this.terminateEvent(this._lastBreath, endTime, events);

    if (events.length > 0) {
      this.emit('eventsUpdated', this, events);
    }

    return endTime;
  }

  private terminateEvent<T extends RecordingEvent<any>>(event : T | null, endTime : RecordingTime, events : RecordingEvent<any>[]) : T | null {
    if (event) {
      event.duration = endTime - event.relativeStart;
      events.push(event);
    }

    return null;
  }
}
