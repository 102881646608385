import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { encode, escape } from 'querystring';
import { INSTRUMENT_TYPES } from 'src/app/model/device-info';
import { ConnectionService } from 'src/app/services/connection.service';
import { FeatureOptions, FeatureSupportService } from 'src/app/services/feature-support.service';

const NO_OVERWRITE = {
  id: 'KEEP',
  title: 'Use reported type'
};

@Component({
  selector: 'app-feature-selector',
  templateUrl: './feature-selector.component.html',
  styleUrls: ['./feature-selector.component.scss']
})
export class FeatureSelectorComponent implements OnInit, OnDestroy {

  Object = Object
  
  readonly INSTRUMENTS = [ NO_OVERWRITE, ...INSTRUMENT_TYPES ];

  constructor(
    protected cs: ConnectionService,
    protected ar: ActivatedRoute,
    protected fs: FeatureSupportService) {

      cs.disableAutoconnect();
  }

  get targets(): FeatureOptions {
    let url = window.location.host
    
    let result : FeatureOptions = {
      "dev":    "https://dev.connect.emeomusic.com",
      "test":   "https://test.connect.emeomusic.com",
      "prod":   "https://connect.emeomusic.com",
      "update": "https://update.connect.emeomusic.com",
    }

    if (url.includes("localhost:4200")) {
      result["local"] = "http://localhost:4200"
    }
    
    return result
  }

  get options(): FeatureOptions {
    return this.fs.supportedFeatures();
  }

  @ViewChildren('featureOption')
  featureOptions!:  QueryList<ElementRef<HTMLInputElement>>;

  @ViewChildren('targetModes')
  targetModes!:  QueryList<ElementRef<HTMLInputElement>>;

  @ViewChild('instrumentType')
  instrumentType!:  ElementRef<HTMLInputElement>;

  @ViewChild('firmwareVersion')
  firmwareVersion!:  ElementRef<HTMLInputElement>;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.cs.enableAutoconnect();
  }
  async go(event: Event, target: string) {

    let features = [] as string[];

    for(var fo of this.featureOptions) {
      let op = fo.nativeElement

      if (op.checked) {
        features.push("feature=" + op.value)
      }
    }

    for(var fo of this.targetModes) {
      let op = fo.nativeElement

      if (op.checked) {
        features.push(op.value + "=1")
      }
    }

    if (this.instrumentType) {

      let op = this.instrumentType.nativeElement;
      
      if (op.value != NO_OVERWRITE.id) {
        features.push("hardware=" + op.value)
      }
    }

    if (this.firmwareVersion.nativeElement.value.length > 0) {
      let op = this.firmwareVersion.nativeElement;
      
      features.push("firmware=" +op.value);
    }


    target = target + (features.length > 0 ? "?" + features.join("&") : '');

    window.open(target, '_blank')?.focus();
  }
}
