import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureSupportService } from '../../services/feature-support.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import webmidi, { Input, InputEventControlchange, InputEventNoteoff, InputEventNoteon, WebMidiEventConnected, WebMidiEventDisconnected } from 'webmidi';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HardwareTypes } from '../../model/device-info';
import { InstrumentViewComponentBaseComponent } from '../../helpers/instrument-view-component-base.component';
import { FingeringModalComponent } from '../fingering-modal/fingering-modal.component';
import { EMEO_SAX_V1_INFO } from '../../model/instruments/emeo-sax-v1';
import { InstrumentInfo } from '../../model/unc-types';

export interface TuningInfo {
  // Colon ':'-separated list of key names
  [ name: string ]: number
}

@Component({
  selector: 'app-midi-playback',
  templateUrl: './midi-playback.component.html',
  styleUrl: './midi-playback.component.scss'
})
export class MidiPlaybackComponent implements OnInit, OnDestroy {

  tunings: Map<string, number>;
  
  midiInputControl : UntypedFormGroup;

  channel?: string;

  detuning : number = -3;

  instrumentInfo: InstrumentInfo = EMEO_SAX_V1_INFO;
  
  @ViewChild('instrumentView')
  instrumentView! : InstrumentViewComponentBaseComponent;
  
  get instrumentType() : HardwareTypes {
    return this.instrumentInfo.hardware.type
  }

  constructor(
    protected fs: FeatureSupportService,
    protected cd: ChangeDetectorRef,
    protected modalService: NgbModal) {

    var map = new Map<string, number>();
    map.set("Eb Tuning", -3);
    map.set("Bb Tuning", +2);
    map.set("Concert Tuning", 0);

    this.tunings = map;

    let control = new UntypedFormControl(null);
    let tuningControl = new UntypedFormControl(-3);

    this.midiInputControl = new UntypedFormGroup({
        midiInputDevice: control,
        tuning: tuningControl
    });

    control.valueChanges.subscribe(val => {
      this.startListening(val);
      cd.detectChanges();
    });

    tuningControl.valueChanges.subscribe(val => {
      this.detuning = parseInt(val);
      cd.detectChanges();
    });

    modalService.activeInstances.subscribe((activeModals: NgbActiveModal[]) => {
      if (activeModals.length > 0) {
        var topmost = activeModals[activeModals.length - 1] as NgbModalRef;

        if (topmost.componentInstance instanceof FingeringModalComponent) {
          this.openInstrumentView = topmost.componentInstance as FingeringModalComponent;
        }
        else {
          this.openInstrumentView = undefined;
        }
      }
      else {
        this.openInstrumentView = undefined;
      }
    })
  }

  private openInstrumentView?: FingeringModalComponent;

  ngOnInit(): void {
    this.startMidi();
  }

  ngOnDestroy(): void {
    this.detachCurrentInput();
  }

  detachCurrentInput() {

    if (this.currentInput) {
      var input = this.currentInput;
      this.currentInput = undefined;

      input.removeListener('noteon',         'all', this.noteOnListener);
      input.removeListener('noteoff',        'all', this.noteOffListener);
      input.removeListener('controlchange',  'all', this.controlListener);

      this.clearView();
    }
  }

  startMidi() {

    if (!webmidi.enabled) {
      webmidi
        .enable( this.handleMidiStart.bind(this), false);
    }
    else {
      this.handleMidiStart( undefined );
    }
  }

  protected currentInput? : Input;

  private noteOnListener = this.onNoteOn.bind(this);
  private noteOffListener = this.onNoteOff.bind(this);
  private controlListener = this.onControl.bind(this);
  
  startListening(inputId: string) {
    this.channel = inputId;

    this.detachCurrentInput();

    if (this.channel) {
      var input = webmidi.getInputById(this.channel);

      if (input) {
        this.currentInput = input;

        input.addListener('noteon',         'all', this.noteOnListener);
        input.addListener('noteoff',        'all', this.noteOffListener);
        input.addListener('controlchange',  'all', this.controlListener);
      }
    }
  }

  private getModelInstrument() : InstrumentViewComponentBaseComponent | undefined {
    if (this.openInstrumentView) {
      return this.openInstrumentView.instrumentView;
    }

    return undefined;
  }

  clearView() {
    this.setPressure(0);
    this.setNote(0);
  }

  private doSetPressure(view: InstrumentViewComponentBaseComponent | undefined, pressure: number) {
    if (view) {
      view.pressure = pressure;
    }
  }

  private setPressure(pressure: number) {
    this.doSetPressure(this.instrumentView, pressure);
    this.doSetPressure(this.getModelInstrument(), pressure);
  }

  private doSetPressed(view: InstrumentViewComponentBaseComponent | undefined, keys: string[]) {
    if (view) {
      view.pressed = keys;
    }
  }

  private setNote(note: number) {

    var entry = Object.entries(this.instrumentInfo.defaultFingerings).find( pair => pair[1] == (note + this.detuning));
    var keys = entry ? entry[0].split(":") : [];

    if (keys) {
      this.doSetPressed(this.instrumentView,keys);
      this.doSetPressed(this.getModelInstrument(),keys);
    }
  }

  onNoteOn(event: InputEventNoteon) {
    console.log("NOTE ON " + event.rawVelocity + " // " + event.note.number);

    this.setPressure(event.rawVelocity);
    this.setNote(event.note.number);
  }

  onNoteOff(event: InputEventNoteoff) {
    console.log("NOTE OFF " + event.rawVelocity + " // " + event.note.number);
    //this.setPressure(0)
    this.setNote(0);
  }

  onControl(event: InputEventControlchange) {
    this.setPressure(event.value);
  }

  handleMidiStart(err: Error | undefined) {
    
    if (err) {
      alert(err)
    }
    else {
      webmidi.addListener('connected', this.onMidiConnected.bind(this));
      webmidi.addListener('disconnected', this.onMidiDisconnected.bind(this));
  
      this.updateDevices();
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  protected inputDevices: Input[] = [];
  
  protected updateDevices() {
    this.inputDevices = [...webmidi.inputs];
  }

  onMidiConnected(event: WebMidiEventConnected) {
    this.updateDevices();
  }
  
  onMidiDisconnected(event: WebMidiEventDisconnected) {
    this.updateDevices();
  }

  formDeviceString(midi : Input) : string {
    if (midi.manufacturer && midi.name) {
      return midi.manufacturer + " - " + midi.name;
    }
    else {
      return midi.manufacturer + midi.name;
    }
  }

  can(...features: string[]) : boolean {
    return this.fs.can({  }, ...features);
  }

  async openInstrumentZoom() {
    let modal = this.modalService.open(FingeringModalComponent, { backdrop: true, fullscreen: true, scrollable: true, centered: true, size: 'lg', backdropClass: 'showToolbar', container: "#fullscreenContainer" });

    let component = modal.componentInstance as FingeringModalComponent;

    component.hardwareInfo = this.instrumentInfo.hardware;
  }
}
